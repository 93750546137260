import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { iCustomer } from '../interfaces/enterprise-provider-customer.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  storage: Storage;
  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
  }

  public listEnterpriseCustomer(
    corporateName?: string
  ): Observable<iCustomer[]> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams();
    if (corporateName) {
      params = new HttpParams().set('customerCorporateName', corporateName);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get<iCustomer[]>(
        `${environment.api}/enterprise-service/list-enterprise-customer`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public findOneEnterpriseCustomer(
    customerDocument?: string
  ): Observable<iCustomer> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    let params = new HttpParams();
    if (customerDocument) {
      params = new HttpParams().set('customerDocument', customerDocument!);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get<iCustomer>(
        `${environment.api}/enterprise-service/find-one-enterprise-customer`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public createOrUpdateCustomerFromEnterprise(body: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    let http = {};

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }

    return this.http
      .put(
        `${environment.api}/enterprise-service/create-or-update-customer-from-enterprise`,
        body,
        http
      )
      .pipe(
        map((res) => {
          try {
            return JSON.stringify(res);
          } catch (e) {
            return res;
          }
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  public deleteCustomerFromEnterprise(
    customerDocument: string
  ): Observable<string> {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const user = this.storage.getItem('user');
    const newUser = user ? JSON.parse(user) : {};

    let http = {};

    let params = new HttpParams()
      .set('customerId', newUser.customerId)
      .set('customerDocument', customerDocument);

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .delete<string>(
        `${environment.api}/enterprise-service/delete-customer-from-enterprise`,
        http
      )
      .pipe(
        map((res) => {
          try {
            return JSON.stringify(res);
          } catch (e) {
            return res;
          }
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }
}
