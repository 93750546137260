import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormsService } from 'src/app/services/forms.service';

@Component({
  selector: 'app-pmo-report',
  templateUrl: './pmo-report.component.html',
  styleUrls: ['./pmo-report.component.scss'],
})
export class PmoReportComponent implements OnInit {
  answer: any;
  evenOddDisplayedColumns: string[] = ['even', 'odd'];

  constructor(
    private formsService: FormsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.formsService.getFormResponse(id as string).subscribe((res: any) => {
      this.answer = res.responses.responses;
    })
  }

  printForm() {
    window.print();
  }

  closeReport() {
  }
}
