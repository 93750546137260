<div class="fill">
  <div class="form">
    <div class="header">
      <img src="../../../assets/images/logo-qima.svg" class="logo" />
      <h1>Recuperar a senha:</h1>
    </div>

    <form [formGroup]="form" (ngSubmit)="submitForm()" action="">
      <div class="form-group" *ngFor="let controlName of form.controls | keyvalue" >
        <input type="text" class="form-input" [id]="controlName.key" [name]="controlName.key" [formControlName]="controlName.key" />
        <label class="form-label" [for]="controlName.key" [ngClass]="{ focus: form.get(controlName.key)?.value.length > 0 }" >
          {{ controlName.key === "user" ? "Digite seu Usuário" : "Digite seu e-mail"  }}
        </label>
        <span class="alert" *ngIf=" form.get(controlName.key)?.errors && form.get(controlName.key)?.touched && form.get(controlName.key)?.dirty">
          Campo obrigatório
        </span>
      </div>
      <div class="row">
        <button type="submit" class="button">Confirmar</button>
        <button type="button" (click)="back()" class="button cancel">Cancelar</button>
      </div>
    </form>
  </div>
</div>
