<div class="laudo-monitoramento-container">
  <div class="header-laudo">
    <button type="button" (click)="downloadPDF()">Download</button>
  </div>
  <div class="laudo" *ngIf="request" #laudo>
    <div class="header">
      <div class="title-area">
        <h2> Laudo do Protocolo de Monitoramento</h2>
        <div class="labels-area">
          <div class="date">
            <span><strong>Data de emissão do laudo:</strong> {{ dataNow | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="emissor">
            <span><strong>Emissor: </strong> {{ 'Safe Gis Trace' }} </span>
          </div>
        </div>
      </div>
      <div class="logo-area">
        <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5251 35.546C12.2597 35.7451 12.0275 36.1266 11.9445 36.2925C19.078 38.5321 22.8107 37.122 25.8798 35.546C33.71 30.7682 34.0087 22.2743 33.1792 18.6246C32.2668 20.2006 30.7737 22.4108 29.1147 22.3573C27.0576 22.2909 25.2715 21.279 24.6355 20.7813C24.6355 20.2006 26.8751 16.5509 28.5341 16.3021C30.0272 16.385 31.6861 16.8827 32.8474 17.2145C32.0179 15.0579 31.6032 13.3989 27.6217 11.2422C24.4365 9.51693 19.0504 9.3068 16.7555 9.41739C17.3638 8.50497 18.8126 6.3815 19.7416 5.18705C27.3728 5.68474 35.9165 12.3206 35.9994 22.772C36.0824 33.2235 27.3728 40.8547 18.4974 40.9376C11.397 41.004 6.69113 37.3708 5.22572 35.546C3.31792 33.6382 0.248844 29.9885 0 22.9379C0 14.7647 5.8893 10.9105 7.71416 9.50034C9.17404 8.37225 11.6957 7.20545 12.774 6.76306C12.5251 7.50959 12.3592 7.67549 11.9445 8.25612C11.6127 8.83676 7.88006 11.9888 7.05058 12.7353C6.2211 13.4818 4.47919 15.3067 4.39624 15.5555C7.11693 14.6265 9.17957 15.5555 9.87081 16.1362C10.3961 16.6062 11.5464 17.6292 11.9445 17.961C12.3427 18.2928 12.4422 18.6523 12.4422 18.7905V19.3711C10.4514 18.2099 8.15652 17.0486 7.38237 17.1315C5.52433 17.3306 3.34557 19.1499 2.48844 20.0347V20.4495C2.76493 20.6706 3.61653 21.2955 4.81098 22.0255C6.30404 22.9379 7.05058 23.1038 7.79711 23.1038C9.78786 22.772 11.5021 21.4172 12.1104 20.7812L10.2026 25.4263C9.95375 26.007 9.95375 26.2558 9.37312 27.0023C8.87543 27.4171 8.46069 27.9148 8.21185 28.2466C7.52753 29.159 7.27177 30.8179 7.21647 31.6474C7.21647 31.6474 7.21647 32.8087 7.38237 33.6382L7.79711 34.0529C7.79711 32.5599 8.79248 31.4815 9.37312 31.0668C9.83763 30.735 10.1749 30.2097 10.2855 29.9885C10.551 29.723 10.6173 28.4125 10.7003 27.8318L11.6127 23.8503L13.2717 27.6659C13.7141 28.6889 14.5988 30.9341 14.5988 31.7304C14.5988 32.7257 14.1841 33.5552 13.8523 34.2188C13.3546 34.9653 12.8569 35.2971 12.5251 35.546Z" fill="#0B8FAF"/>
          <path d="M14.35 19.0394C15.8762 19.1057 16.8108 20.3389 17.0873 20.9472C15.6938 21.1462 14.6818 21.0854 14.35 21.0301C13.6035 20.8642 13.3546 19.3711 14.35 19.0394Z" fill="#0B8FAF"/>
          <path d="M23.8061 19.4541C23.4743 19.5205 22.6724 20.3665 22.313 20.7813C22.6171 20.9195 23.2586 21.1794 23.3913 21.1131C23.524 21.0467 23.6678 20.8642 23.7231 20.7813C23.889 20.3112 24.1379 19.3877 23.8061 19.4541Z" fill="#0B8FAF"/>
          <path d="M19.2439 14.1454C20.7702 15.1408 23.0872 16.3297 24.0549 16.7998C23.3084 15.6385 21.6494 13.1501 17.9997 13.0671C15.0799 13.0007 13.4099 15.4173 12.9399 16.6339L15.0965 15.3067C16.5896 14.3943 17.585 13.3989 19.2439 14.1454Z" fill="#0B8FAF"/>
          <path d="M11.6127 13.6477C10.7501 13.9795 10.3132 13.6754 10.2026 13.4818V13.233C11.2809 11.5187 13.5371 7.87456 13.9353 7.0119C14.4329 5.93357 14.0182 5.68473 13.7694 5.68473C10.5344 5.85063 4.89393 5.68473 3.89855 5.10409C3.10225 4.63959 3.56676 1.86912 3.89855 0.541956C6.30404 1.53733 7.38237 1.28849 9.70491 1.28849C12.691 1.20554 15.4836 0.652558 17.0043 0.293119C17.6956 0.0719245 19.2273 -0.237748 19.8246 0.293119C20.4218 0.823986 19.9075 2.28387 19.5757 2.94745C17.1149 6.26537 12.0772 13.0505 11.6127 13.6477Z" fill="#0B8FAF"/>
          <path d="M19.6587 27.2512L17.4191 28.7443C17.4191 28.7719 17.4357 28.8604 17.502 28.9931C19.078 28.9102 20.3222 28.4954 21.0688 28.3295C21.8153 28.1636 22.0642 28.4954 22.2301 28.6613C22.3628 28.794 22.7277 29.5461 22.8936 29.9055C22.9766 29.7949 23.1425 29.524 23.1425 29.3249C23.0098 28.595 23.0319 27.9148 23.0595 27.6659C23.0595 27.2678 23.3202 26.5876 23.6402 25.8411C23.8179 25.4263 23.4466 25.1498 23.2254 25.0946C22.4789 25.1775 22.2135 25.4263 21.4835 25.4263C20.5711 25.4263 19.9075 24.8457 19.2439 24.5969C18.7131 24.3978 18.0274 25.0669 17.7509 25.4263C17.8062 25.5369 17.9831 25.8411 18.2486 26.1729C18.514 26.5047 19.2992 27.03 19.6587 27.2512Z" fill="#0B8FAF"/>
        </svg>
        <img src="../../../../../assets/images/selo_safe_gis.svg" alt="" class="nav-image"/>
      </div>
    </div>
    <hr>
    <div class="first-area">
      <div class="sections">
        <section>
          <h3>Dados da Solicitação</h3>
          <span><strong>Nº da Solicitação:</strong> {{ request.requestNumber }}</span>
          <span><strong>Data Análise:</strong> {{ request.requestDate | date:'dd/MM/yyyy' }} </span>
          <span><strong>Tipo de Análise: </strong> {{ getTipoAnalise(request.status) }} </span>
          <!-- <span><strong>Dados do Solicitante:</strong> - </span> -->
          <span [className]="getStatusColor(request.status)"><strong>Resultado da Análise:</strong> {{ getStatusName(request.status)}}</span>
          <span><strong>Empresa de Geomonitoramento:</strong> Geoflorestas </span>
        </section>
        <section>
          <h3>Propriedade Analisada</h3>
          <span><strong>Nome da Propriedade:</strong> {{ request.producer.place.nickname }}</span>
          <span><strong>CAR:</strong> {{ request.producer.place.code }} </span>
          <span><strong>Município:</strong> {{ request.producer.place.address.city }} </span>
          <span><strong>UF:</strong> {{ request.producer.place.address.stateIdentifier }} </span>
          <span><strong>Nome do Produtor:</strong> {{ request.producer.corporateName }} </span>
          <span><strong>Documento Proprietário:</strong> {{ maskCpfCnpj(request.producer.document) }} </span>
        </section>
      </div>
      <div class="legends">
        <h3>Legenda Resultado Análise:</h3>
        <span><strong class="green"> Habilitado: </strong> Sem nenhum problema na análise automatizada.</span>
        <span><strong class="green"> Habilitado AD: </strong> Apresentou algum problema durante a análise automatizada, e após uma análise de um especialista, foi habilitado.</span>
        <span><strong class="red"> Não Habilitado AD: </strong> Apresentou algum problema durante a análise automatizada, sendo necessário uma análise de um especialista, havendo inconsistências.</span>
        <span><strong class="yellow"> Em Análise AD: </strong> Apresentou algum problema durante a análise automatizada, sendo necessário uma análise de um especialista, verificando possíveis inconsistências.</span>
      </div>
    </div>

    <div class="table-area">
      <table>
        <thead>
          <tr>
            <th>Critérios Analisados:</th>
            <th>Resultado:</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="getRequestData('slavery')">
            <th> {{ getTitle('slavery') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('slavery')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('slavery')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('ibamaEmbargo')">
            <th> {{ getTitle('ibamaEmbargo') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('ibamaEmbargo')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('ibamaEmbargo')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <th> {{ getTitle('deterAmazon') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('deterAmazon')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('deterAmazon')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <th> {{ getTitle('deterCerrado') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('deterCerrado')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('deterCerrado')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <th> {{ getTitle('deterPantanal') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('deterPantanal')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('deterPantanal')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesAmazon') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesAmazon')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesAmazon')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesAtlanticForest') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesAtlanticForest')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesAtlanticForest')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesCaatinga') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesCaatinga')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesCaatinga')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesCerrado') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesCerrado')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesCerrado')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesPampa') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesPampa')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesPampa')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <th> {{ getTitle('prodesPantanal') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('prodesPantanal')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('prodesPantanal')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('publicForests')">
            <th> {{ getTitle('publicForests') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('publicForests')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('publicForests')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('quilombolaAreas')">
            <th> {{ getTitle('quilombolaAreas') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('quilombolaAreas')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('quilombolaAreas')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('indigenousLand')">
            <th> {{ getTitle('indigenousLand') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('indigenousLand')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('conservationUnit')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('conservationUnit')">
            <th> {{ getTitle('conservationUnit') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('conservationUnit')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('conservationUnit')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('archeologicalSites')">
            <th> {{ getTitle('archeologicalSites') }} </th>
            <td>
              <span class="green" *ngIf="!getResponseData('archeologicalSites')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('archeologicalSites')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
          <tr *ngIf="getRequestData('miningAreas')">
            <th>{{ getTitle('miningAreas') }}</th>
            <td>
              <span class="green" *ngIf="!getResponseData('miningAreas')">
                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                </svg>
              </span>
              <span class="red" *ngIf="getResponseData('miningAreas')">
                <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                </svg>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="result">
      <h3>Resultado Análise:</h3>
      <app-resultado-analise
        *ngIf="getRequestData('slavery')"
        [title]="getTitle('slavery')"
        [legend]="getLegend('slavery')"
        [red]="getResponseData('slavery')"
        [imageUrl]="getImage('slavery')"
      ></app-resultado-analise>

      <app-resultado-analise
      *ngIf="getRequestData('ibamaEmbargo')"
      [title]="getTitle('ibamaEmbargo')"
      [legend]="getLegend('ibamaEmbargo')"
      [red]="getResponseData('ibamaEmbargo')"
      [imageUrl]="getImage('ibamaEmbargo')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('deter')"
        [title]="getTitle('deterAmazon')"
        [legend]="getLegend('deterAmazon')"
        [red]="getResponseData('deterAmazon')"
        [imageUrl]="getImage('deterAmazon')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('deter')"
        [title]="getTitle('deterCerrado')"
        [legend]="getLegend('deterCerrado')"
        [red]="getResponseData('deterCerrado')"
        [imageUrl]="getImage('deterCerrado')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('deter')"
        [title]="getTitle('deterPantanal')"
        [legend]="getLegend('deterPantanal')"
        [red]="getResponseData('deterPantanal')"
        [imageUrl]="getImage('deterPantanal')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesAmazon')"
        [legend]="getLegend('prodesAmazon')"
        [red]="getResponseData('prodesAmazon')"
        [imageUrl]="getImage('prodesAmazon')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesAtlanticForest')"
        [legend]="getLegend('prodesAtlanticForest')"
        [red]="getResponseData('prodesAtlanticForest')"
        [imageUrl]="getImage('prodesAtlanticForest')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesCaatinga')"
        [legend]="getLegend('prodesCaatinga')"
        [red]="getResponseData('prodesCaatinga')"
        [imageUrl]="getImage('prodesCaatinga')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesCerrado')"
        [legend]="getLegend('prodesCerrado')"
        [red]="getResponseData('prodesCerrado')"
        [imageUrl]="getImage('prodesCerrado')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesPampa')"
        [legend]="getLegend('prodesPampa')"
        [red]="getResponseData('prodesPampa')"
        [imageUrl]="getImage('prodesPampa')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('prodes')"
        [title]="getTitle('prodesPantanal')"
        [legend]="getLegend('prodesPantanal')"
        [red]="getResponseData('prodesPantanal')"
        [imageUrl]="getImage('prodesPantanal')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('publicForests')"
        [title]="getTitle('publicForests')"
        [legend]="getLegend('publicForests')"
        [red]="getResponseData('publicForests')"
        [imageUrl]="getImage('publicForests')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('quilombolaAreas')"
        [title]="getTitle('quilombolaAreas')"
        [legend]="getLegend('quilombolaAreas')"
        [red]="getResponseData('quilombolaAreas')"
        [imageUrl]="getImage('quilombolaAreas')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('indigenousLand')"
        [title]="getTitle('indigenousLand')"
        [legend]="getLegend('indigenousLand')"
        [red]="getResponseData('indigenousLand')"
        [imageUrl]="getImage('indigenousLand')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('conservationUnit')"
        [title]="getTitle('conservationUnit')"
        [legend]="getLegend('conservationUnit')"
        [red]="getResponseData('conservationUnit')"
        [imageUrl]="getImage('conservationUnit')"
      ></app-resultado-analise>

      <app-resultado-analise
        *ngIf="getRequestData('archeologicalSites')"
        [title]="getTitle('archeologicalSites')"
        [legend]="getLegend('archeologicalSites')"
        [red]="getResponseData('archeologicalSites')"
        [imageUrl]="getImage('archeologicalSites')"
      ></app-resultado-analise>

    </div>

    <div class="params">
      <h3>Parâmetros da Análise:</h3>
      <table>
        <thead>
          <tr>
            <th>Critérios Analisados</th>
            <th>
              <div class="table-title">
                <span class="red">
                  <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.87359 6.20607C5.95407 6.28655 5.99929 6.39571 5.99929 6.50953C5.99929 6.62335 5.95407 6.7325 5.87359 6.81299C5.79311 6.89347 5.68395 6.93869 5.57013 6.93869C5.45631 6.93869 5.34715 6.89347 5.26667 6.81299L3 4.54561L0.732617 6.81227C0.652135 6.89276 0.542977 6.93797 0.429157 6.93797C0.315338 6.93797 0.20618 6.89276 0.125697 6.81227C0.0452147 6.73179 1.19928e-09 6.62263 0 6.50881C-1.19928e-09 6.39499 0.0452147 6.28584 0.125697 6.20535L2.39308 3.93868L0.126411 1.6713C0.0459286 1.59082 0.000714039 1.48166 0.00071404 1.36784C0.000714041 1.25402 0.0459286 1.14486 0.126411 1.06438C0.206894 0.983899 0.316052 0.938685 0.429871 0.938685C0.543691 0.938685 0.652849 0.983899 0.733332 1.06438L3 3.33176L5.26738 1.06402C5.34786 0.983542 5.45702 0.938328 5.57084 0.938328C5.68466 0.938328 5.79382 0.983542 5.8743 1.06402C5.95478 1.14451 6 1.25367 6 1.36749C6 1.4813 5.95478 1.59046 5.8743 1.67095L3.60692 3.93868L5.87359 6.20607Z" fill="#F43841"/>
                  </svg>
                </span>
                <span>Não Habilitado</span>
              </div>
            </th>
            <th>
              <div class="table-title">
                <span class="green">
                  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.86368 5.72713L0.220001 3.08345L0.880922 2.42252L2.86368 4.40529L7.11908 0.149887L7.78 0.810807L2.86368 5.72713Z" fill="#99CA3C"/>
                  </svg>
                </span>
                <span>Habilitado</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="getRequestData('slavery')">
            <td>{{ getTitle('slavery') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('ibamaEmbargo')">
            <td>{{ getTitle('ibamaEmbargo') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <td>{{ getTitle('deterAmazon') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <td>{{ getTitle('deterCerrado') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('deter')">
            <td>{{ getTitle('deterPantanal') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesAmazon') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesAtlanticForest') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesCaatinga') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesCerrado') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesPampa') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('prodes')">
            <td>{{ getTitle('prodesPantanal') }}</td>
            <td>Maior que 6,25 ha</td>
            <td>Menor que 6,25 ha</td>
          </tr>
          <tr *ngIf="getRequestData('publicForests')">
            <td>{{ getTitle('publicForests') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('quilombolaAreas')">
            <td>{{ getTitle('quilombolaAreas') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('indigenousLand')">
            <td>{{ getTitle('indigenousLand') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('conservationUnit')">
            <td>{{ getTitle('conservationUnit') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('archeologicalSites')">
            <td>{{ getTitle('archeologicalSites') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
          <tr *ngIf="getRequestData('miningAreas')">
            <td>{{ getTitle('miningAreas') }}</td>
            <td>Maior que 0 ha</td>
            <td>0 ha</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="protocolos">
      <h3>Protocolo de Monitoramento Socio Ambiental</h3>
      <ol>
        <li *ngIf="getRequestData('ibamaEmbargo')">
          <strong>Área Embargada:</strong> os embargos exibidos tratam dos termos de embargos vigentes lavrados pelo IBAMA. Lei nº 12.527, de 18 de novembro de 2011 Art. 8º, § 2º Lei Nº 10.650, de 16 de abril de 2003 Art. 4º , incisos III e V . Decreto Nº 6.514, de 22 de julho de 2008. Art. 18, §1º, art. 149, incisos I e II, parágrafo único.Áreas Embargadas divulgadas no site do Instituto Brasileiro do Meio Ambiente e dos Recursos Naturais Renováveis (IBAMA).
        </li>
        <li *ngIf="getRequestData('slavery')">
          <strong>Trabalho Escravo:</strong> Cadastro de Empregadores que tenham submetido trabalhadores a condições análogas à de escravo (Lista Suja do Trabalho Escravo) - (Portaria Interministerial MTPS/MMIRDH nº 4 de 11/05/2016.
        </li>
        <li *ngIf="getRequestData('quilombolaAreas')">
          <strong>Areas Quilombolas :</strong> Áreas quilombolas são territórios ocupados por comunidades remanescentes de quilombos, grupos formados por descendentes de escravizados (INCRA).
        </li>
        <li *ngIf="getRequestData('archeologicalSites')">
          <strong>Sitios Arquiologicos:</strong>Sítios arqueológicos são locais que contêm vestígios de atividades humanas antigas, como ruínas, artefatos e estruturas (IPHAN).
        </li>
        <li *ngIf="getRequestData('miningAreas')">
          <strong>Areas de mineração:</strong>Áreas de mineração referem-se a regiões geográficas onde ocorrem atividades de extração de minerais, como metais, minerais industriais, pedras preciosas, carvão, petróleo e gás natural(ANM / SIGMINE).
        </li>
        <li *ngIf="getRequestData('deter')">
          <strong>Deter:</strong>Áreas de desmatamento detectadas após 05 de outubro de 2009 da base oficial do Projeto de Detecção do Desmatamento em Tempo Real (DETER), ou outro (s) que venha (m) a substituí-lo se for o caso.
        </li>
        <li *ngIf="getRequestData('prodes')">
          <strong>Desmatamento Recente(PRODES):</strong>Áreas de desmatamento detectadas após 05 de outubro de 2009 da base oficial do Projeto de Monitoramento do Desflorestamento na Amazônia Legal (PRODES), ou outro (s) que venha (m) a substituí-lo se for o caso.
        </li>
        <li *ngIf="getRequestData('conservationUnit')">
          <strong>Unidade de Conservação:</strong>Áreas de Unidades de Conservação da base oficial do Instituto Brasileiro do Meio Ambiente e dos Recursos Naturais Renováveis (IBAMA).
        </li>
        <li *ngIf="getRequestData('indigenousLand')">
          <strong>Terra Indígena:</strong>Áreas Indígenas da base oficial da Fundação Nacional do Índio (FUNAI).
        </li>
        <li *ngIf="getRequestData('publicForests')">
          <strong>Florestas publicas (CNFP):</strong>O CNFP é formado pelo Cadastro de Florestas Públicas da União, pelos Cadastros de Florestas Públicas dos estados, Distrito Federal e municípios e está em processo de interligação ao Sistema Nacional de Cadastro Rural (SNCR) do Instituto Nacional de Colonização e Reforma Agrária (Ministerio da Agricultura e pecuária).
        </li>
      </ol>
    </div>
    <!-- <img [src]="blobUrl" alt="Imagem"> -->
  </div>
</div>
