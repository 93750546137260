import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { iDynamicForm, iStepForm, iStepFormNew } from '../interfaces/dynamic-form-interface';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private token: any;
  private item: any;

  constructor(private http: HttpClient) {
    this.item = sessionStorage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};
  }

  listAllForms() {
    let http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    return this.http.get(`${environment.api}/api/forms`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getFormById(hash: string) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Access-Control-Allow-Origin': '*'
      }),
    };

    const mock = '66bb3fb126f78b6bc24aa35c';

    return this.http.get(`${environment.api}/api/forms/${hash}`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  registerForm(payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.post(
      `${environment.api}/api/forms`,
      payload,
      http
    );
  }

  submitResponse(hash: string, payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.post(
      `${environment.api}/api/form-responses?formId=${hash}`,
      payload,
      http
    );
  }

  editResponse(responseId: string, hash: string, payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.put(
      `${environment.api}/api/form-responses/${responseId}?formId=${hash}`,
      payload,
      http
    );
  }

  getFormResponse(responseId: string) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Access-Control-Allow-Origin': '*'
      }),
    };
    return this.http.get(`${environment.api}/api/form-responses/form/${responseId}`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getFormList(payload: {
    formId?: string;
    providerDocument?: string;
    providerCorporateName?: string;
    code?: string;
    user?: string;
    title?:string;
    createdAt?: string;
    id?: string;
    pageIndex: number;
    pageSize: number;
  }) {
    let params = new HttpParams()
      .set('page', payload.pageIndex)
      .set('size', payload.pageSize);

    if (payload.formId) {
      params = params.set('formId', payload.formId);
    }

    if (payload.id) {
      params = params.set('id', payload.id);
    }

    if (payload.createdAt) {
      params = params.set('createdAt', payload.createdAt);
    }

    if (payload.title) {
      params = params.set('title', payload.title);
    }

    if (payload.user) {
      params = params.set('user', payload.user);
    }

    if (payload.code) {
      params = params.set('code', payload.code);
    }

    if (payload.providerDocument) {
      params = params.set('providerDocument', payload.providerDocument);
    }

    if (payload.providerCorporateName) {
      params = params.set('providerCorporateName', payload.providerCorporateName);
    }

    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Access-Control-Allow-Origin': '*'
      }),
      params,
    };
    return this.http.get(`${environment.api}/api/dynamic-forms/filtered`, http).pipe(
      (res) => res,
      (error) => error
    );
  }

  getResponseListByFormId(payload: {
    formId?: string;
    providerDocument?: string;
    providerCorporateName?: string;
    code?: string;
    user?: string;
    title?:string;
    createdAt?: string;
    id?: string;
    pageIndex: number;
    pageSize: number;
  }) {
    let params = new HttpParams()
      .set('page', payload.pageIndex)
      .set('size', payload.pageSize);

    if (payload.formId) {
      params = params.set('formId', payload.formId);
    }

    if (payload.id) {
      params = params.set('id', payload.id);
    }

    if (payload.createdAt) {
      params = params.set('createdAt', payload.createdAt);
    }

    if (payload.title) {
      params = params.set('title', payload.title);
    }

    if (payload.user) {
      params = params.set('user', payload.user);
    }

    if (payload.code) {
      params = params.set('code', payload.code);
    }

    if (payload.providerDocument) {
      params = params.set('providerDocument', payload.providerDocument);
    }

    if (payload.providerCorporateName) {
      params = params.set('providerCorporateName', payload.providerCorporateName);
    }

    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Access-Control-Allow-Origin': '*'
      }),
      params,
    };
    return this.http.get(`${environment.api}/api/form-responses/filtered`, http).pipe(
      (res) => res,
      (error) => error
    );
  }


  registerDynamicForm(payload: iDynamicForm){
    if (payload.id) {
      return this.upadateDynamicForm(payload);
    } else {
      return this.createDynamicForm(payload);
    }
  }

  createDynamicForm(payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.post(
      `${environment.api}/api/dynamic-forms`,
      payload,
      http
    );
  }


  upadateDynamicForm(payload: any) {
    const http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    return this.http.put(
      `${environment.api}/api/dynamic-forms`,
      payload,
      http
    );
  }

  listDynamicForm(id?: string) {
    let http = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    return this.http.get(`${environment.api}/api/dynamic-forms${id ? '/' + id : ''}`, http).pipe(
      (res) => res,
      (error) => error
    );
  }
}
