import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { PostalCodeService } from 'src/app/services/postal-code.service';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
import { ProdutoService } from 'src/app/services/produto.service';

@Component({
  selector: 'app-modal-providers',
  templateUrl: './modal-providers.component.html',
  styleUrls: ['./modal-providers.component.scss'],
})
export class ModalProvidersComponent implements OnInit {
  private storage!: Storage;
  auditingCompanyList: Array<any> = [];
  protected openOptions: boolean = false;
  loading: boolean = false;
  enterpriseCreated: any;
  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;
  productsList: any[] = [];

  types: Array<{ name: string; value: string | number }> = [
    { name: 'Fazenda', value: 'FARM' },
    { name: 'Packing', value: 'STORE' },
    { name: 'Processador', value: 'PROCESSOR' },
    { name: 'Indústria', value: 'WAREHOUSE' },
    { name: 'Frigorífico', value: 'FRIDGE' },
    { name: 'Laboratório', value: 'LABORATORY' },
    { name: 'Auditor', value: 'AUDITING' },
    { name: 'Outros', value: 'OTHERS' },
  ];

  protected form: FormGroup = new FormGroup({
    providerName: new FormControl('', Validators.required),
    cnpj: new FormControl('', Validators.required),
    type: new FormControl(''),
    postalCode: new FormControl(''),
    street: new FormControl(''),
    number: new FormControl(''),
    neighborhood: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    enterpriseCode: new FormControl(null),
    economicGroup: new FormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<ModalProvidersComponent>,
    private fornecedoresService: FornecedoresService,
    public dialog: MatDialog,
    private postalCodeService: PostalCodeService,
    private productsService: ProdutoService,
    @Inject(MAT_DIALOG_DATA) public dataModal: any
  ) {}

  ngOnInit(): void {
    if (this.dataModal.document) {
      this.form.get('cnpj')?.setValue(this.dataModal.document);
    }
  }

  public confirmModal(): void {
    if (!this.form.valid) {
      return;
    }

    const data = this.form.get('company')?.value;
    this.dialogRef.close(data);
  }

  public onClickOpenOptions(): void {
    this.openOptions = true;
  }

  changeProductValue(product: any) {
    this.openOptions = false;
    if(product) {
      this.productsList.push(product)
    }
  }

  getProviders() {
    this.loading = true;
    this.fornecedoresService
      .getProviderOutsourcedByName(this.form.get('provider')?.value)
      .subscribe(
        (res: any) => {
          this.auditingCompanyList = res.content;
          this.openOptions = true;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('#input-provider') && this.openOptions) {
      this.openOptions = false;
    }
  }

  getValue(provider: any) {
    this.form.get('provider')?.setValue(provider.corporateName);
    this.form.get('documentProvider')?.setValue(provider.document);
    this.form.get('company')?.setValue(provider);
    this.openOptions = false;
  }

  openModalConfirm() {
    if(!this.dataModal.isProduct) {
      if (this.dataModal.auditing) {
        this.form.get('type')?.setValue('AUDITING');
      }
  
      this.form.markAllAsTouched();
  
      if (!this.form.invalid) {
        if (this.dataModal.enterpriseDocument === this.form.get('cnpj')?.value) {
          this.openModalSuccessOrError(
            true,
            'O Documento cadastrado não pode ser o mesmo do fornecedor selecionado'
          );
        } else {
          const dialogref = this.dialog.open(ModalConfirmComponent, {
            data: {
              fornecedor: this.form.value,
            },
          });
          dialogref.afterClosed().subscribe(
            (result) => {
              if (result.confirm) {
                this.loading = true;
  
                if (this.dataModal.laboratory || this.dataModal.auditingSocial) {
                  this.fornecedoresService
                    .createOrUpdateProviderFromEnterprise(this.form.value)
                    .subscribe({
                      next: (res) => {
                        this.enterpriseCreated = res;
                        this.openModalSuccessOrError(false);
                        this.loading = false;
                      },
                      error: (error) => {
                        this.openModalSuccessOrError(true);
                        this.loading = false;
                      },
                    });
                } else if (
                  this.dataModal &&
                  this.dataModal.filial &&
                  !this.dataModal.auditing
                ) {
                  this.form
                    .get('economicGroup')
                    ?.setValue(this.dataModal.economicGroup);
                  this.fornecedoresService
                    .registerEnterprise(this.form.value)
                    .subscribe({
                      next: (response) => {
                        this.enterpriseCreated = response;
                        this.openModalSuccessOrError(false);
                        this.loading = false;
                      },
                      error: (error) => {
                        this.openModalSuccessOrError(true);
                        this.loading = false;
                      },
                    });
                } else {
                  if (this.dataModal.isCustomer) {
                    this.fornecedoresService
                      .createOrUpdateProviderFromEnterpriseByDocument(
                        this.dataModal.enterpriseDocument,
                        this.form.value
                      )
                      .subscribe({
                        next: (response) => {
                          this.enterpriseCreated = response;
                          this.openModalSuccessOrError(false);
                          this.loading = false;
                        },
                        error: (error) => {
                          this.openModalSuccessOrError(true);
                          this.loading = false;
                        },
                      });
                  } else {
                    this.fornecedoresService
                      .createOrUpdateProviderFromEnterprise(this.form.value)
                      .subscribe({
                        next: (response) => {
                          this.enterpriseCreated = response;
                          this.openModalSuccessOrError(false);
                          this.loading = false;
                        },
                        error: (error) => {
                          this.openModalSuccessOrError(true);
                          this.loading = false;
                        },
                      });
                  }
                }
              }
            },
            (error) => {
              this.enterpriseCreated = null;
            }
          );
        }
      }
    } else {
      this.loading = true;
      this.productsService.inserirFornecedor(this.productsList, {document: this.dataModal.providerDocument}).subscribe({
        next: (response) => {
          this.openModalSuccessOrError(false);
          this.loading = false;
        },
        error: (error) => {
          this.openModalSuccessOrError(true);
          this.loading = false;
        },
      });
    }
  }

  removeProduct(index: number) {
    if(this.productsList && this.productsList.length > 0) {
      this.productsList.splice(index, 1);
    }
  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog
        .open(ModalConfirmComponent, {
          data: {
            success: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          this.closeModal();
        });
    }

    this.loading = false;
  }

  closeModal() {
    this.dialogRef.close(this.enterpriseCreated ? this.enterpriseCreated : null);
  }

  public onPostalCodeChange() {
    this.postalCodeService
      .getAddresByPostalCode(this.form.get('postalCode')?.value)
      .subscribe(
        (res) => {
          this.form.get('street')?.setValue(res.logradouro);
          this.form.get('neighborhood')?.setValue(res.bairro);
          this.form.get('city')?.setValue(res.localidade);
          this.form.get('state')?.setValue(res.uf);
        },
        (error) => {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Não foi possível encontrar o CEP informado!',
            },
          });
        }
      );
  }
}
