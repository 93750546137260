import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitacoesService {

  protected storage: Storage;
  protected httpOptions: any;
  protected token: any;
  protected customerId: string = '';

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;

    const item = this.storage.getItem('token');
    this.token = item ? JSON.parse(item) : {};

    const itemUser = this.storage.getItem('user');
    const user = itemUser ? JSON.parse(itemUser) : {};
    this.customerId = user.customerId;

    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      })
    };
  }

  public registerRequest(body: any): Observable<Object> {
    return this.http.post<Object>(`${environment.api}/geo-monitoring/register`, body, this.httpOptions);
  }

  public listNewRequest(): Observable<Object> {
    return this.http.get<Object>(`${environment.api}/geo-monitoring/list/new-requests/${this.customerId}`,  {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Content-Type': 'application/json',
      }),
      params: {
        sort: false,
      },
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public listAllRequests(): Observable<Object> {
    return this.http.get<Object>(`${environment.api}/geo-monitoring/list`, this.httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }
  convertKmlToGeoJSON(kmlUrl: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `${this.token}`,
    });

    return this.http.get<any>(kmlUrl, { headers, responseType: 'text' as 'json' });
  }
  public validateRequest(id: string) {
    return this.http.post<Object>(`${environment.api}/geo-monitoring/send-to-safegistrace/${id}`, {}, this.httpOptions);
  }

  sendMessage(body: any) {
    return this.http.post<Object>(`${environment.api}/geo-monitoring/send-message`, body, this.httpOptions);
  }

  public listRequestAnswered(): Observable<Object> {
    return this.http.get<Object>(`${environment.api}/geo-monitoring/list/with-response/${this.customerId}`, {
      headers: new HttpHeaders({
        Authorization: this.token,
        'Content-Type': 'application/json',
      }),
      params: {
        sort: false,
      },
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getRequestById(id: string) {
    return this.http.get<Object>(`${environment.api}/geo-monitoring/find/${id}`, this.httpOptions);
  }

  public updateRequest(body: any) {
    return this.http.post<Object>(`${environment.api}/geo-monitoring/update`, body, this.httpOptions);
  }

  public getId(hash: string) {
    return this.http.get<Object>(`${environment.api}/geo-monitoring/find/${hash}`, this.httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getListTowns(state: any): Observable<Object> {
    return this.http.get<Object>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/distritos?orderBy=nome`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getCityById(cityId: any): Observable<Object> {
    return this.http.get<Object>(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${cityId}`).pipe(
      map((response: any) => {
        if (response.length === 0) {
          throw Error;
        } else {
          return response;
        }
      }),
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public getFile(file: string) {
    return this.http.get(`${environment.api}/reader-service/download?`,
      {
        headers: new HttpHeaders({
          Authorization: this.token,
          'Content-Type': 'application/json',
        }),
        responseType: 'blob',
        params: {
          filePath: file,
        },
      });
  }

  public getGeoJsonFile(file: string) {
    var apiUrl = `${environment.api}/reader-service/download?filePath=` + file;
    return fetch(apiUrl, {
      headers: {
        'Authorization': this.token
      }
    })
      .then(response => response.json())

  }
  public async getTextFile(file: string) {
    var apiUrl = `${environment.api}/reader-service/download?filePath=` + file;
    return fetch(apiUrl, {
      headers: {
        'Authorization': this.token
      }
    })
      .then(response => response.text())

  }
  public uploadFile(uploadFiles: any) {
    const formData = new FormData();
    for (var i = 0; i < uploadFiles.length; i++) {
      formData.append('file', uploadFiles[i]);
    }

  
    return this.http.post(`${environment.api}/reader-service/upload `, formData, {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      responseType: 'text'
    });
  }

  getStatusColor(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'green',
      'POST_ANALYSIS_ENABLED': 'green',
      'NOT_ENABLED': 'yellow',
      'POST_ANALYSIS_NOT_ENABLED': 'red',
      'ANALYSIS_IN_PROGRESS': 'yellow',
      'VALIDATING': 'yellow',
      'PENDING': 'red',
    };
    return statusColors[status] || '';
  }

  getStatusName(status: string): string {
    const statusColors: { [key: string]: string } = {
      'ENABLED': 'Habilitado',
      'POST_ANALYSIS_ENABLED': 'Habilitado AD',
      'NOT_ENABLED': 'Em Análise AD',
      'POST_ANALYSIS_NOT_ENABLED': 'Não Habilitado AD',
      'ANALYSIS_IN_PROGRESS': 'Analisando',
      'VALIDATING': 'Validando',
      'PENDING': 'Pendente',
    };
    return statusColors[status] || '';
  }
}
