
export interface iDynamicForm {
  hash?: string;
  id?: string;
  code?: string;
  user?: string;
  title: string;
  description?: string;
  steps: iStepForm[];
  version?: string;
  instructionFile?: string;
  createdAt?: string;
}

export interface iStepForm {
  hash?: string;
  id?: string;
  title: string;
  description?:string;
  questions: iQuestion[];
  required?: boolean;
  active?: boolean;
  order?: number;
}


export interface iStepFormNew {
  position: number | string;
  form : {
    hash?: string;
    id?: string;
    title: string;
    description?:string;
    questions: iQuestion[];
    active?: boolean;
  }
}

export interface iQuestion {
  hash?: string;
  id?: string;
  title: string;
  description?: string;
  required?: boolean;
  type: 'TEXT' | 'DATE' | 'CHECKBOX' | 'MULTIPLE_RESPONSES' | 'FILE' | 'RADIO' | 'SELECT' | 'DYNAMIC_MATRIX' | 'TEXTAREA' | 'FIXED_IMAGE' | 'FIXED_TEXT';
  options?: iOption[];
  dynamicMatrix?: iDynamicMatrix;
  value: any;
  order: number;
  weight: number;
  tag?: string;
}

export interface iDynamicMatrix {
  columns?: iColumn[];
  rows?: any[];
  headerRows?: string[];
}
export interface iColumn {
  title: string;
  type: 'TEXT' | 'DATE' | 'CHECKBOX' | 'FILE' | 'RADIO' | 'SELECT' | 'BLOCK';
  options?: iOption[];
  questions?: iQuestion[];
  value: any;
}

export interface iOption {
  hash?: string;
  id?: string;
  title: string;
  description?: string;
  type: 'CHECKBOX' | 'RADIO';
  conditionalQuestions: iQuestion[];
  value: boolean | string;
  order?: number;
  weight?: number;
  required?: boolean;
}
