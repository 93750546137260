import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuditoriaService } from 'src/app/services/auditoria.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ProviderSelectFilterComponent } from '../provider-select-filter/provider-select-filter.component';
export interface Address {
  city: string;
  district: string;
  number: string | number;
  stateIdentifier: string;
  stateName: string;
  street: string;
  zipCode: string;
}

export interface Company {
  document: string | number;
  address?: Address;
  corporateName?: string;
  documentType?: string;
  enterpriseCode?: string;
  outsourced?: Array<any>;
  places?: Array<any>;
}
export interface SocialAudit {
  auditedCompany?: Company;
  auditingDate: string | number | Date;
  auditingCompany: Company;
  auditor?: string;
  certificates: Array<any>;
  cultures: string;
  exclusiveBrand: boolean | string;
  expiration: string | number | Date;
  features: Array<any>;
  id: string;
  productEvaluation?: string;
  productionSystem?: string;
  products?: Array<any>;
  providerCompany: Company;
  qualityCertificate: boolean;
  reportFileName?: string;
  result: string;
  score: number;
  status: string;
  type: string;
  year?: string | number;
};

@Component({
  selector: 'app-see-datas-sutentabilty',
  templateUrl: './see-datas-sutentabilty.component.html',
  styleUrls: ['./see-datas-sutentabilty.component.scss'],
  providers: [DatePipe]
})
export class SeeDatasSutentabiltyComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  yearSwitched: string = "";
  displayedColumns: string[] = [
    'providerCompany.enterpriseCode',
    'providerCompany.document',
    'auditedCompany.corporateName',
    'auditedCompany.address.city',
    'auditingDate',
    'expiration',
    'classification',
    'score',
    'status',
    'result',
    'actions'
  ];

  formFilter!: FormGroup;
  @ViewChild('inputProvider') inputProvider!: ProviderSelectFilterComponent;

  tableActive: boolean = false;
  classProduct: string | null = '';
  tableData: Array<SocialAudit> = [];
  auditList: Array<SocialAudit> = [];
  loading: boolean = false;
  firstRender: boolean = true;

  pageIndex: number = 0;
  pageSize: number = 15;
  length: number = 0;

  private storage!: Storage;
  protected isUserProvider: boolean = false;
  protected user: any;

  protected providerDocument : any = "";

  constructor(
    private auditoriaService: AuditoriaService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.storage = window.sessionStorage;
    this.formFilter = this.fb.group({
      year: ['', Validators.required],
      brand: [''],
      provider: [null],
      status: [null],
    });
  }

  ngOnInit(): void {

    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};

    if (this.user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      this.isUserProvider = true;
    }
    this.checkFilters();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  checkFilters() {
    const item = sessionStorage.getItem('filtro-social-audit');
    const filters = item ? JSON.parse(item): null;

    if (filters) {
      this.formFilter.get('year')?.setValue(filters.year);

      if (filters.brand) {
        this.formFilter.get('brand')?.setValue(filters.brand);
      }
      if (filters.provider) {
        this.formFilter.get('provider')?.setValue(filters.provider);
      }

      if (filters.status) {
        this.formFilter.get('status')?.setValue(filters.status);
      }

      if (filters.providerName) {
        setTimeout(() => {
          this.inputProvider.setValue(filters.providerName);
        }, 500);
      }
      if ((filters.pageIndex || this.pageIndex === 0) && filters.pageSize) {
        this.paginate({ pageIndex: filters.pageIndex, pageSize: filters.pageSize } as PageEvent);
      }
    }
  }

  saveFilters() {
    sessionStorage.removeItem('filtro-social-audit');

    const filter = {
      type: this.classProduct,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      year: this.formFilter.get('year')?.value,
      brand: this.formFilter.get('brand')?.value,
      provider: this.formFilter.get('provider')?.value,
      status: this.formFilter.get('status')?.value,
      providerName: this.inputProvider.inputProvider.value,
    }

    const filterJSON = JSON.stringify(filter);
    sessionStorage.setItem('filtro-social-audit', filterJSON);
  }

  onClickCheck(checkbox: any, type: string) {
    checkbox[type] = !checkbox[type];
  }


  formatCnpj(value: string) {
    return formatCpfCnpj(value)
  }


  paginate(pageEvent?: PageEvent) {
    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }
    this.loading = true;
    this.classProduct = '';
    this.classProduct = localStorage.getItem('classProduct');

    const options = {
      subType: !this.isUserProvider? this.classProduct : '',
      year: this.formFilter.get('year')?.value,
      brand: this.formFilter.get('brand')?.value,
      provider: this.formFilter.get('provider')?.value,
      status: this.formFilter.get('status')?.value,
      page: this.pageIndex,
      size: this.pageSize,
    };
    if (this.isUserProvider) {
      const customerId = this.user.customerId;
      this.providerDocument = atob(customerId);

      options.provider = this.providerDocument;

      this.auditoriaService.socialAuditList(options).subscribe({
        next: (res: any) => {
          this.tableData = res.content;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;

          this.auditList = this.tableData;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.tableActive = true;
          this.loading = false;
          this.saveFilters();
        },
        error: (err) => {
          this.tableActive = false;
          this.loading = false;
        },
      });
    } else {
      this.auditoriaService.socialAuditList(options).subscribe({
        next: (res: any) => {
          this.tableData = res.content;
          if (this.tableData && this.tableData.length <= 0) {
            this._snackBar.open("Nenhum registro encontrado para o filtro solicitado", undefined, {
              duration: 2 * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "right",
              verticalPosition: "top"
            });
          }
          this.pageIndex = res.currentPage;
          this.length = res.totalItems;

          this.auditList = this.tableData;
          this.dataSource = new MatTableDataSource(this.tableData);
          this.tableActive = true;
          this.loading = false;
          this.saveFilters();
        },
        error: (err) => {
          this.tableActive = false;
          this.loading = false;
        },
      });
    }
  };

  cleanFilters() {
    sessionStorage.removeItem('filtro-social-audit');
    this.pageIndex = 0;
    this.pageSize = 15;
    this.inputProvider.cleanFilter();
    this.formFilter.get('year')?.setValue('');
    this.formFilter.get('brand')?.setValue('');
    this.formFilter.get('provider')?.setValue('');
    this.formFilter.get('status')?.setValue('');

    this.tableData = [];
    this.dataSource = new MatTableDataSource([] as Array<any>);
  }

  public editAudit(item: any) {
    let url = window.location.pathname;
    let parts = url.split('/');
    let type = parts.pop();
    const options = ['FLV', 'Ovos', 'Suinos', 'Aves', 'Pescado'];

    function capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (type && type !== 'flv') {
      type = capitalizeFirstLetter(type);
    }
    if (type && type === 'flv') {
      type = type.toUpperCase();
    }

    if (this.isUserProvider) {
      this.router.navigate(['/minha-empresa/nova-auditoria-social'], { queryParams: { type: type, id: item.id } });
    } else {
      this.router.navigate(['/nova-auditoria-social'], { queryParams: { type: type, id: item.id } });
    }

  }

  deleteAudit(item: any) {
    const dialogref = this.dialog.open(ModalConfirmComponent, {
      data: {
        text: 'Deseja deletar Auditoria?',
        delete: true,
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.loading = true;
        this.auditoriaService.deletarAuditoria(item.id).subscribe(res => {
          this.loading = false;
          const dialogref = this.dialog.open(ModalConfirmComponent, {
            data: {
              success: true,
              successText: 'Auditoria Apagada com sucesso!',
              delete: true,
            }
          });
          dialogref.afterClosed().subscribe(result => {
            this.paginate();
          });

        }, error => {
          this.loading = false;
          const dialogref = this.dialog.open(ModalConfirmComponent, {
            data: {
              error: true,
              errorText: 'Não foi possível apagar a Auditoria',
              delete: true,
            }
          });
          dialogref.afterClosed().subscribe(result => {
            this.paginate();
          });
        })
      }
    });
  }


  public exportToExcel(json: any[], fileName: string): void {
    if(json.length > 0){
      this.loading = true;
      this.classProduct = '';
      this.classProduct = localStorage.getItem('classProduct');

      const options = {
        subType: !this.isUserProvider? this.classProduct : '',
        year: this.formFilter.get('year')?.value,
        brand: this.formFilter.get('brand')?.value,
        provider: this.formFilter.get('provider')?.value,
        status: this.formFilter.get('status')?.value,
      };

      if (this.isUserProvider) {
        const customerId = this.user.customerId;
        this.providerDocument = atob(customerId);

        options.provider = this.providerDocument;

        this.auditoriaService.exportSocialAuditList(options).subscribe({
          next: (res: any) => {
            let response = res;
            this.dialog.open(ModalConfirmComponent, {
                data : {
                  success: true,
                  successText: response,
                }
            })
            this.loading = false;
          },
          error: (err) => {
            this.dialog.open(ModalConfirmComponent, {
              data : {
                error: true,
                errorText: 'Falha ao gerar arquivo para exportação!',
              }
            })
            this.loading = false;
          },
        });
      } else {
        this.auditoriaService.exportSocialAuditList(options).subscribe({
          next: (res: any) => {
            let response = res;
            this.dialog.open(ModalConfirmComponent, {
                data : {
                  success: true,
                  successText: response,
                }
            })
            this.loading = false;
          },
          error: (err) => {
            this.dialog.open(ModalConfirmComponent, {
              data : {
                error: true,
                errorText: 'Falha ao gerar arquivo para exportação!',
              }
            })
            this.loading = false;
          },
        });
      }
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      })
    }
    return returnFeature.value;
  }

  maskCpfCnpj(value: string): string {

    if (value == null) {
      return '-';
    }

    const documentNumber: string = value.replace(/\D/g, '');
    if (documentNumber.length === 11) {
      return `${documentNumber.substr(0, 3)}.***.***-${documentNumber.substr(9)}`;
    } else if (documentNumber.length === 14) {
      return `${documentNumber.substr(0, 2)}.***.***/****-${documentNumber.substr(12)}`;
    }
    return documentNumber;
  }

  getStatusColor(status: string): string {
    status = this.capitalizarString(status);
    const statusColors: { [key: string]: string } = {
      'Em andamento': 'yellow',
      'Liberado': 'green',
      'Suspenso': 'red',
    };

    return statusColors[status] || '';
  }

  submitForm() {
    this.formFilter.markAllAsTouched();
    if (this.formFilter.valid) {
      this.paginate();
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: 'Preencha os filtros',
        }
      });
    }
  }

  changeProviderValue(event: any) {
    this.formFilter.get('provider')?.setValue(event);
  }

  public capitalizarString(string : string) {
    if (string == undefined || string.trim() === '') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  public formatCpfCnpj(item: string) {
    return formatCpfCnpj(item);
  }

}
