import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecuperarSenhaComponent } from './pages/recuperar-senha/recuperar-senha.component';
import { SessionLoginComponent } from './pages/session-login/session-login.component';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';


export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'sumario', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/sumario/sumario.module').then(e => e.SumarioModule) },
  { path: 'auditoria', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/auditorias/auditorias.module').then(e => e.AuditoriasModule) },
  { path: 'auditoria-social', canActivate: [AuthGuard], loadChildren: () => import('../../src/app/pages/social-audit/social-audit.module').then(e => e.SocialAuditModule) },
  { path: 'nova-auditoria-social', canActivate: [AuthGuard], loadChildren: () => import('./pages/social-audit/form-social-audit/form-social-audit.module').then(e => e.FormSocialAuditModule) },
  { path: 'dashboards', canActivate: [AuthGuard], loadChildren: () => import('./pages/dashboards/dashboards.module').then(e => e.DashboardsModule) },
  { path: 'processos-perguntas', canActivate: [AuthGuard], loadChildren: () => import('./pages/processos-perguntas/processos-perguntas.module').then(e => e.ProcessosPerguntasModule) },
  { path: 'fornecedores', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/fornecedores/fornecedores.module').then(e => e.FornecedoresModule) },
  { path: 'rastreabilidade', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/rastreabilidade/rastreabilidade.module').then(e => e.RastreabilidadeModule) },
  { path: 'nivel-servico', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/nivel-servico/nivel-servico.module').then(e => e.NivelServicoModule) },
  { path: 'processos', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/processos/processos.module').then(e => e.ProcessosModule) },
  { path: 'analises', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/analises/analises.module').then(e => e.AnalisesModule) },
  { path: 'sustentabilidade', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/sustentabilidade/sustentabilidade.module').then(e => e.SustentabilidadeModule) },
  { path: 'produtos', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/produto/produto.module').then(e => e.ProdutoModule) },
  { path: 'inspecoes', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/inspecoes/inspecoes.module').then(e => e.InspecoesModule) },
  { path: 'manuais', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/manuais/manuais.module').then(e => e.ManuaisModule) },
  { path: 'relatorios', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/relatorios/relatorios.module').then(e => e.RelatoriosModule) },
  { path: 'ranking', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/ranking/ranking.module').then(e => e.RankingModule) },
  { path: 'integracao', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/integracao/integracao.module').then(e => e.IntegracaoModule) },
  { path: 'minha-empresa', canActivate: [AuthGuard], loadChildren: () => import('../app/pages/paginas-usuario-fornecedor/paginas-usuario-fornecedor.module').then(e => e.PaginasUsuarioFornecedorModule) },
  { path: 'login/:id', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'session-login', component: SessionLoginComponent },
  { path: 'recuperar-senha', component: RecuperarSenhaComponent },
  { path: 'trocar-senha', component: TrocarSenhaComponent },
  { path: "**", canActivate: [AuthGuard], component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
