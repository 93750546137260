<div class="fill">
  <div class="form">
    <div class="header">
      <img src="../../../assets/images/logo-qima.svg" class="logo" />
      <h1>Trocar a senha:</h1>
    </div>

    <form [formGroup]="form" (ngSubmit)="submitForm()" action="">

      <span class="alert" *ngIf="nameUserIsNull">
        Usuário não encontrado! Faça login novamente!
      </span>

      <div class="form-group" >
        <input type="password" class="form-input" id="newPassword" name="newPassword" formControlName="newPassword" (click)="showValidations()"/>
        <label class="form-label" for="newPassword" [ngClass]="{ focus: form.get('newPassword')?.value.length > 0 }" >
          Digite a nova senha
        </label>
        <span class="alert" *ngIf="form.get('newPassword')?.errors?.['required'] && form.get('newPassword')?.touched && form.get('newPassword')?.dirty">
          Campo obrigatório
        </span>
      </div>

      <div class="validation" *ngIf="showValidation">
        <h1>Regras</h1>
        <div [ngClass]="{'success': !form.get('newPassword')?.errors?.['maiuscula'], 'error': form.get('newPassword')?.errors?.['maiuscula']}"><div class="title">ABC</div><div class="description">1 letra maiúscula</div></div>
        <div [ngClass]="{'success': !form.get('newPassword')?.errors?.['minuscula'], 'error': form.get('newPassword')?.errors?.['minuscula']}"><div class="title">abc</div><div class="description">1 letra minúscula</div></div>
        <div [ngClass]="{'success': !form.get('newPassword')?.errors?.['numeros'], 'error': form.get('newPassword')?.errors?.['numeros']}"><div class="title">123</div><div class="description">1 número</div></div>
        <div [ngClass]="{'success': !form.get('newPassword')?.errors?.['caractere'], 'error': form.get('newPassword')?.errors?.['caractere']}"><div class="title">!*?</div><div class="description">1 caractere especial</div></div>
        <div [ngClass]="{'success': !form.get('newPassword')?.errors?.['tamanho'], 'error': form.get('newPassword')?.errors?.['tamanho']}"><div class="title">***</div><div class="description">No mínimo 8 caracteres</div></div>
      </div>

      <div class="row">
        <button type="submit" class="button" [disabled]="!form.valid || nameUserIsNull">Confirmar</button>
        <button type="button" (click)="back()" class="button cancel">Cancelar</button>
      </div>
    </form>
  </div>
</div>
