<div style="height: 100%;" >
  <div class="fill" [style]="isSafeGis || isCarrefour ? 'justify-content: center; align-items: center;' : ''">
    <video autoplay="autoplay" loop preload="auto" poster="../../../assets/images/qima-poster.png" [muted]="'muted'"
      class="bg_video" *ngIf="!isSafeGis && !isCarrefour">
      <source src="../../../assets/video/video-login.mp4" type="video/mp4" *ngIf="showVideo">
    </video>

    <div class="form">
      <div class="header">
        <img src="../../../assets/images/logo-qima.svg" class="logo" />
        <h1>Login: Área Restrita</h1>
      </div>

      <form [formGroup]="form" (ngSubmit)="submitForm()" action="">
        <div class="form-group" *ngFor="let controlName of form.controls | keyvalue: originalOrder">
          <input [type]="controlName.key === 'password' ? 'password' : 'text'" class="form-input" [id]="controlName.key"
            [name]="controlName.key" [formControlName]="controlName.key" />
          <label class="form-label" [for]="controlName.key"
            [ngClass]="{ 'focus': form.get(controlName.key)?.value.length > 0 }">
            {{ controlName.key === "user" ? "Usúario" : "Senha" }}
          </label>
          <img class="password-eye" src="../../../assets/images/olho.png" (click)="show()" [ngStyle]="{ display: controlName.key === 'password'? '' : 'none'}">
          <span class="alert" *ngIf="
            form.get(controlName.key)?.errors &&
            form.get(controlName.key)?.touched &&
              form.get(controlName.key)?.dirty
          ">Campo obrigatório</span>
        </div>
        <a [routerLink]="['/recuperar-senha']"> Esqueci minha senha </a>
        <button type="submit" [disabled]="form.invalid" class="button">
          Entrar
        </button>
      </form>
    </div>
  </div>
</div>
