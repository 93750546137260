<div class="content">

  <a [routerLink]="['/auditoria']" routerLinkActive="active" class="item" *ngIf="!isCarrefour">
    <span class="icon">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M46.875 44.6875L42.5 40.3125C43.2813 39.0625 43.75 37.5 43.75 35.9375C43.75 31.5625 40.3125 28.125 35.9375 28.125C31.5625 28.125 28.125 31.5625 28.125 35.9375C28.125 40.3125 31.5625 43.75 35.9375 43.75C37.5 43.75 39.0625 43.2813 40.3125 42.5L44.6875 46.875L46.875 44.6875ZM31.25 35.9375C31.25 33.2812 33.2812 31.25 35.9375 31.25C38.5938 31.25 40.625 33.2812 40.625 35.9375C40.625 38.5938 38.5938 40.625 35.9375 40.625C33.2812 40.625 31.25 38.5938 31.25 35.9375ZM12.5 25H28.125V28.125H12.5V25ZM12.5 15.625H31.25V18.75H12.5V15.625Z"
          fill="white" />
        <path
          d="M21.875 43.2812L13.75 38.9062C9.0625 36.5625 6.25 31.7188 6.25 26.5625V6.25H37.5V23.4375H40.625V6.25C40.625 4.53125 39.2188 3.125 37.5 3.125H6.25C4.53125 3.125 3.125 4.53125 3.125 6.25V26.5625C3.125 32.9688 6.5625 38.75 12.1875 41.7188L21.875 46.875V43.2812Z"
          fill="white" />
      </svg>
    </span>
    <span class="text">Auditorias</span></a>

  <a [routerLink]="['/analises/flv']" class="item" *ngIf="(!isCarrefour && !isAuditing) || isQuality">
    <span class="icon">
      <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.9165 2H2.08301V14.25H42.9165V2Z" stroke="white" stroke-width="3" stroke-linejoin="round" />
        <path d="M2.08301 38.7808L14.5127 26.2245L21.2288 32.6558L29.4394 24.4585L34.0128 28.9175" stroke="white"
          stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M42.9165 13.4048V39.9466M2.08301 13.4048V27.6965M11.2859 40.7918H42.9165M15.3539 8.12501H36.7915M8.20803 8.12195H9.22887"
          stroke="white" stroke-width="3" stroke-linecap="round" />
      </svg>
    </span>
    <span class="text">Análises</span></a>

  <a [routerLink]="[ isCarrefour ? '/rastreabilidade/bovinos' : '/rastreabilidade']" routerLinkActive="active" class="item" *ngIf="!isAuditing">
    <span class="icon">
      <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 2C10.6961 2 1.9375 10.7586 1.9375 21.5625C1.9375 32.3664 10.6961 41.125 21.5 41.125C32.3039 41.125 41.0625 32.3664 41.0625 21.5625"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M21.5 11.7812C19.5655 11.7813 17.6744 12.3549 16.0658 13.4297C14.4573 14.5045 13.2036 16.0321 12.4633 17.8194C11.723 19.6067 11.5293 21.5734 11.9067 23.4707C12.2841 25.3681 13.2157 27.111 14.5836 28.4789C15.9515 29.8468 17.6944 30.7784 19.5918 31.1558C21.4892 31.5332 23.4558 31.3395 25.2431 30.5992C27.0304 29.8589 28.558 28.6052 29.6328 26.9967C30.7076 25.3882 31.2813 23.497 31.2813 21.5625M21.5 21.5625L28.3469 14.7091"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28.3467 7.89701V14.7156H35.2207L41.0623 8.84688H34.2187V2L28.3467 7.89701Z" stroke="white"
          stroke-width="3" stroke-linejoin="round" />
      </svg>
    </span>
    <span class="text">Rastreabilidade</span></a>