<div class="formulario-analise-content">
  <div class="header-page">
    <h1>{{ isEditing ? "Edição de Análise" : "Inclusão Individual" }}</h1>
    <div class="back-button-area">
      <button type="button" class="button" [routerLink]="['/analises/todos']">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_460_4379)">
            <path
              d="M6.1998 2.875L1.9248 7.15L6.1998 11.425"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.9248 7.1499H9.10258C14.0579 7.1499 18.0748 11.2097 18.0748 16.2181V17.1249"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_460_4379">
              <rect width="19" height="19" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span>Voltar</span>
      </button>
    </div>
  </div>

  <form [formGroup]="formNewAnalyses">
    <div class="datas-supplier">
      <h4>Dados do fornecedor</h4>
      <hr />
      <div class="input-form">
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="cnpj"
            name="cnpj"
            formControlName="cnpj"
            mask="000.000.000-00||00.000.000/0000-00"
            (change)="findProviderByDocumentOrCode()"
          />
          <label
            class="form-label"
            for="cnpj"
            [ngClass]="{ focus: formNewAnalyses.get('cnpj')?.value }"
          >
            CNPJ/CPF
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('cnpj')?.errors &&
              (formNewAnalyses.get('cnpj')?.touched ||
                formNewAnalyses.get('cnpj')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
        <app-provider-select-filter
          #inputProvider
          (handleChangeProviderValue)="changeProviderValue($event)"
          [customerDocument]="customerDocument"
          [isUserProvider]="isUserProvider"
          style="margin-top: 20px; padding-right: 12px"
        ></app-provider-select-filter>
        <!-- <div class="form-group" tabindex="-1">
            <input type="text" class="form-input" id="nomeFornecedor" name="nomeFornecedor"
                formControlName="nomeFornecedor" (input)="findProviderByName()" />
            <label for="nomeFornecedor"
                [ngClass]="{ focus : formNewAnalyses.get('nomeFornecedor')?.value }"
                class="form-label">
                Nome do fornecedor
            </label>
            <span class="alert" *ngIf="formNewAnalyses.get('nomeFornecedor')?.errors && (formNewAnalyses.get('nomeFornecedor')?.touched || formNewAnalyses.get('nomeFornecedor')?.dirty)">
              Campo obrigatório
            </span>
        </div> -->
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="codigoFornecedor"
            name="codigoFornecedor"
            formControlName="codigoFornecedor"
            (change)="findProviderByDocumentOrCode()"
          />
          <label
            for="codigoFornecedor"
            [ngClass]="{ focus: codeProvider }"
            class="form-label"
          >
            Código do fornecedor
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="cep"
            name="cep"
            [value]="zip"
            [readonly]="true"
          />
          <label for="cep" [ngClass]="{ focus: zip }" class="form-label">
            CEP
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="estado"
            name="estado"
            [value]="state"
            [readonly]="true"
          />
          <label for="estado" class="form-label" [ngClass]="{ focus: state }">
            Estado
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="cidade"
            name="cidade"
            [value]="city"
            [readonly]="true"
          />
          <label for="cidade" class="form-label" [ngClass]="{ focus: city }">
            Cidade
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="bairro"
            name="bairro"
            [value]="district"
            [readonly]="true"
          />
          <label
            for="bairro"
            class="form-label"
            [ngClass]="{ focus: district }"
          >
            Bairro
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="rua"
            name="rua"
            [value]="street"
            [readonly]="true"
            [value]="street"
          />
          <label for="rua" [ngClass]="{ focus: street }" class="form-label">
            Rua
          </label>
        </div>
        <div class="form-group" tabindex="-1">
          <input
            type="text"
            class="form-input"
            id="numero"
            name="numero"
            [value]="number"
            [readonly]="true"
          />
          <label for="numero" [ngClass]="{ focus: number }" class="form-label">
            Número
          </label>
        </div>

        <!-- <div class="form-group" tabindex="-1">
            <input type="date" class="form-input" id="dataRecebimento" name="dataRecebimento"
                formControlName="dataRecebimento" />
            <label class="form-label" for="dataRecebimento"
                [ngClass]="{ focus: formNewAnalyses.get('dataRecebimento')?.value && formNewAnalyses.get('dataRecebimento')?.value.length > 0 }">
                Data de recebimento
            </label>
            <span class="alert" *ngIf="formNewAnalyses.get('dataRecebimento')?.errors && (formNewAnalyses.get('dataRecebimento')?.touched || formNewAnalyses.get('dataRecebimento')?.dirty)">
              Campo obrigatório
            </span>
        </div> -->
        <div *ngIf="error" style="width: 100%">
          <p class="msg-error">Não encontrado</p>
        </div>
      </div>
    </div>
    <div class="info-analyses">
      <h4>Informações de análise</h4>
      <hr />
      <div class="input-form">
        <div *ngIf="datasEnterprise" class="select-container">
          <select
            (change)="setDatasProduct()"
            class="input-search form-input"
            formControlName="produto"
            required
          >
            <option value="" disabled></option>
            <option
              *ngFor="let product of products"
              [value]="removeSpaces(product.name)"
            >
              {{ product.name }}
            </option>
          </select>
          <label
            style="color: #585858"
            class="form-label"
            [ngClass]="{ focus: formNewAnalyses.get('produto')?.value }"
          >
            Produto
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('produto')?.errors &&
              (formNewAnalyses.get('produto')?.touched ||
                formNewAnalyses.get('produto')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div style="display: flex" *ngIf="!isUserProvider">
          <button
            type="button"
            style="
              width: 36px;
              height: 36px;
              margin-right: 5px;
              margin-top: 30px;
              margin-right: 4px;
            "
            title="Cadastrar novo produto"
            (click)="openModalLaboratorys(true)"
            class="button save"
          >
            +
          </button>

          <div
            class="tooltip-container"
            style="margin-top: 32px"
            
          >
            <span class="tooltip-trigger">?</span>
            <div class="tooltip">Cadastrar novo produto</div>
          </div>
        </div>

        <div class="select-container" *ngIf="datasEnterprise">
          <select
            class="input-search form-input"
            formControlName="laboratorio"
            required
          >
            <option value="" disabled></option>
            <option
              *ngFor="let lab of laboratorios"
              [value]="lab.corporateName"
            >
              {{ lab.corporateName }}
            </option>
          </select>
          <label
            style="color: #585858"
            class="form-label"
            [ngClass]="{ focus: formNewAnalyses.get('laboratorio')?.value }"
          >
            Laboratório
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('laboratorio')?.errors &&
              (formNewAnalyses.get('laboratorio')?.touched ||
                formNewAnalyses.get('laboratorio')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div style="display: flex">
          <button
            type="button"
            style="
              width: 36px;
              height: 36px;
              margin-right: 5px;
              margin-top: 30px;
              margin-right: 4px;
            "
            title="Cadastrar novo laboratório"
            (click)="openModalLaboratorys()"
            class="button save"
          >
            +
          </button>

          <div
            class="tooltip-container"
            style="margin-top: 32px"
            
          >
            <span class="tooltip-trigger">?</span>
            <div class="tooltip">Cadastrar novo laboratório</div>
          </div>
        </div>

        <div class="form-group" tabindex="-1" *ngIf="datasEnterprise">
          <input
            type="date"
            class="form-input"
            id="dataColeta"
            name="dataColeta"
            formControlName="dataColeta"
            
          />
          <label
            class="form-label"
            for="dataColeta"
            [ngClass]="{ focus: formNewAnalyses.get('dataColeta')?.value }"
          >
            Data de coleta
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('dataColeta')?.errors &&
              (formNewAnalyses.get('dataColeta')?.touched ||
                formNewAnalyses.get('dataColeta')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="form-group" tabindex="-1" *ngIf="datasEnterprise">
          <input
            type="text"
            class="form-input"
            id="solicitante"
            name="solicitante"
            formControlName="solicitante"
            
          />
          <label
            class="form-label"
            for="solicitante"
            [ngClass]="{ focus: formNewAnalyses.get('solicitante')?.value }"
          >
            Solicitante
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('solicitante')?.errors &&
              (formNewAnalyses.get('solicitante')?.touched ||
                formNewAnalyses.get('solicitante')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap">
        <div class="form-group" tabindex="-1">
          <input
            type="date"
            class="form-input"
            id="dataEntradaEmbalamento"
            name="dataEntradaEmbalamento"
            formControlName="dataEntradaEmbalamento"
            
          />
          <label
            class="form-label"
            for="dataEntradaEmbalamento"
            [ngClass]="{
              focus: formNewAnalyses.get('dataEntradaEmbalamento')?.value
            }"
          >
            Data de fabricação
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('dataEntradaEmbalamento')?.errors &&
              (formNewAnalyses.get('dataEntradaEmbalamento')?.touched ||
                formNewAnalyses.get('dataEntradaEmbalamento')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div
          class="select-container"
          *ngIf="formNewAnalyses.get('cnpj')?.value"
        >
          <select
            class="input-search form-input"
            formControlName="empresaColeta"
            required
          >
            <option value="" disabled></option>
            <option
              *ngFor="let collector of collectors"
              [value]="collector.corporateName"
            >
              {{ collector.corporateName }}
            </option>
          </select>
          <label
            style="color: #585858"
            class="form-label"
            [ngClass]="{ focus: formNewAnalyses.get('empresaColeta')?.value }"
          >
            Empresa que realizou a coleta
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('empresaColeta')?.errors &&
              (formNewAnalyses.get('empresaColeta')?.touched ||
                formNewAnalyses.get('empresaColeta')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap">
        <div
          class="form-group"
          tabindex="-1"
          *ngIf="formNewAnalyses.get('cnpj')?.value"
        >
          <input
            type="text"
            class="form-input"
            id="ean"
            name="ean"
            formControlName="ean"
            
          />
          <label
            class="form-label"
            for="ean"
            [ngClass]="{ focus: formNewAnalyses.get('ean')?.value }"
          >
            EAN
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('ean')?.errors &&
              (formNewAnalyses.get('ean')?.touched ||
                formNewAnalyses.get('ean')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div
          class="form-group"
          tabindex="-1"
          *ngIf="formNewAnalyses.get('cnpj')?.value"
        >
          <input
            type="text"
            class="form-input"
            id="plu"
            name="plu"
            formControlName="plu"
            
          />
          <label
            class="form-label"
            for="plu"
            [ngClass]="{ focus: formNewAnalyses.get('plu')?.value }"
          >
            PLU
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('plu')?.errors &&
              (formNewAnalyses.get('plu')?.touched ||
                formNewAnalyses.get('plu')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div
          class="form-group"
          tabindex="-1"
          *ngIf="formNewAnalyses.get('cnpj')?.value"
        >
          <input
            type="text"
            class="form-input"
            id="codigo"
            name="codigo"
            formControlName="codigo"
            
          />
          <label
            class="form-label"
            for="codigo"
            [ngClass]="{ focus: formNewAnalyses.get('codigo')?.value }"
          >
            Código
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('codigo')?.errors &&
              (formNewAnalyses.get('codigo')?.touched ||
                formNewAnalyses.get('codigo')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
      </div>
    </div>
    <div class="info-analyses">
      <h4>Rastreio</h4>
      <hr />
      <div class="input-form">
        <div class="form-group" [tabIndex]="-1">
          <input
            type="text"
            class="form-input"
            id="loteProduto"
            name="loteProduto"
            formControlName="loteProduto"
            
          />
          <label
            class="form-label"
            for="loteProduto"
            [ngClass]="{ focus: formNewAnalyses.get('loteProduto')?.value }"
          >
            Lote do Produto
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('loteProduto')?.errors &&
              (formNewAnalyses.get('loteProduto')?.touched ||
                formNewAnalyses.get('loteProduto')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="form-group" [tabIndex]="-1">
          <input
            type="text"
            class="form-input"
            id="codigoRastreio"
            name="codigoRastreio"
            formControlName="codigoRastreio"
            
          />
          <label
            class="form-label"
            for="codigoRastreio"
            [ngClass]="{ focus: formNewAnalyses.get('codigoRastreio')?.value }"
          >
            Código de rastreio
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('codigoRastreio')?.errors &&
              (formNewAnalyses.get('codigoRastreio')?.touched ||
                formNewAnalyses.get('codigoRastreio')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="form-group" [tabIndex]="-1">
          <input
            type="text"
            class="form-input"
            id="relatorioEnsaio"
            name="relatorioEnsaio"
            formControlName="relatorioEnsaio"
            
          />
          <label
            class="form-label"
            for="relatorioEnsaio"
            [ngClass]="{ focus: formNewAnalyses.get('relatorioEnsaio')?.value }"
          >
            Relatório de ensaio nº
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('relatorioEnsaio')?.errors &&
              (formNewAnalyses.get('relatorioEnsaio')?.touched ||
                formNewAnalyses.get('relatorioEnsaio')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
      </div>
    </div>
    <div class="result-analyses">
      <h4>Resultado da análise</h4>
      <hr />
      <div class="input-form">
        <div class="select-container">
          <select
            class="input-search form-input"
            (change)="getStatus()"
            formControlName="residueResult"
            required
          >
            <option [disabled]></option>
            <option
              *ngFor="let option of optionsResultResidue"
              [value]="option"
            >
              {{ option }}
            </option>
          </select>
          <label
            style="color: #585858"
            class="form-label"
            [ngClass]="{ focus: formNewAnalyses.get('residueResult')?.value }"
          >
            Resultado Dos Resíduos
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('residueResult')?.errors &&
              (formNewAnalyses.get('residueResult')?.touched ||
                formNewAnalyses.get('residueResult')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="select-container">
          <select
            class="input-search form-input"
            (change)="getStatus()"
            formControlName="planoAcao"
            required
          >
            <option [disabled]></option>
            <option *ngFor="let op of actionPlan">{{ op }}</option>
          </select>
          <label
            style="color: #585858"
            [ngClass]="{ focus: formNewAnalyses.get('planoAcao')?.value }"
            class="form-label"
          >
            Plano de ação
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('planoAcao')?.errors &&
              (formNewAnalyses.get('planoAcao')?.touched ||
                formNewAnalyses.get('planoAcao')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="select-container">
          <input
            class="input-search form-input"
            formControlName="status"
            [readonly]="true"
          />
          <label
            style="color: #585858"
            class="form-label"
            [ngClass]="{ focus: formNewAnalyses.get('status')?.value }"
          >
            Status
          </label>
          <span
            class="alert"
            *ngIf="
              formNewAnalyses.get('status')?.errors &&
              (formNewAnalyses.get('status')?.touched ||
                formNewAnalyses.get('status')?.dirty)
            "
          >
            Campo obrigatório
          </span>
        </div>
      </div>

      <div style="display: flex; margin-top: 10px">
        <div>
          <input
            (change)="changeInputResidue()"
            type="checkbox"
            formControlName="lmr"
          />
          <label style="font-size: 15px; color: black; margin-left: 10px"
            >LMR</label
          >
        </div>
        <div style="margin-left: 30px">
          <input
            (change)="changeInputResidue()"
            type="checkbox"
            formControlName="nr"
          />
          <label style="font-size: 15px; color: black; margin-left: 10px"
            >NR</label
          >
        </div>
        <div style="margin-left: 30px">
          <input
            (change)="changeInputResidue()"
            type="checkbox"
            formControlName="na"
          />
          <label style="font-size: 15px; color: black; margin-left: 10px"
            >NA</label
          >
        </div>
      </div>
    </div>
    <div style="margin-top: 30px">
      <div class="form-group" tabindex="-1">
        <textarea
          style="width: 510px; height: 100px; resize: none; padding-top: 20px"
          class="input-search form-input"
          formControlName="obs"
          
        ></textarea>
        <label
          style="color: #585858; position: relative; top: -105px"
          class="form-label focus"
        >
          Observação
        </label>
        <span
          class="alert"
          *ngIf="
            formNewAnalyses.get('obs')?.errors &&
            (formNewAnalyses.get('obs')?.touched ||
              formNewAnalyses.get('obs')?.dirty)
          "
        >
          Campo obrigatório
        </span>
      </div>
    </div>
  </form>

  <div class="buttons-container">
    <div class="send-file-buttons-area">
      <div class="file-send-area" *ngIf="isEditing">
        <button
          type="button"
          class="button upload-action-plan"
          (click)="openModalUploadDocument('gpa')"
          
        >
          <img
            src="../../../../assets/images/upload-icon.svg"
            alt="Icone upload"
          />
          <span>Carregar Documento</span>
        </button>
        <div *ngIf="archiveDocument.length > 0" class="file-area">
          <span>{{ archiveDocument[0].name }}</span>
          <button
            type="button"
            class="button btn-delete"
            (click)="deleteFileDocument('gpa')"
            [ngStyle]="{
              display: !showButtonDelete ? 'none' : ''
            }"
          >
            <img
              src="../../../../assets/images/delete-icon.svg"
              alt="Icone de apagar"
            />
          </button>
          <button
            type="button"
            class="button btn-delete"
            (click)="downloadFile('gpa')"
            *ngIf="isEditing"
          >
            <img
              src="../../../../assets/images/download-icon.svg"
              alt="Icone de download"
            />
          </button>
        </div>
      </div>
      <div class="file-send-area" *ngIf="isEditing">
        <button
          type="button"
          class="button upload-action-plan"
          (click)="openModalUploadDocument('provider')"
          
        >
          <img
            src="../../../../assets/images/upload-icon.svg"
            alt="Icone upload"
          />
          <span>Carregar Documento Fornecedor</span>
        </button>
        <div *ngIf="archiveDocument.length > 0" class="file-area">
          <span>{{ archiveDocument[0].name }}</span>
          <button
            type="button"
            class="button btn-delete"
            (click)="deleteFileDocument('provider')"
            [ngStyle]="{
              display: !showButtonDelete ? 'none' : ''
            }"
          >
            <img
              src="../../../../assets/images/delete-icon.svg"
              alt="Icone de apagar"
            />
          </button>
          <button
            type="button"
            class="button btn-delete"
            (click)="downloadFile('provider')"
            *ngIf="isEditing"
          >
            <img
              src="../../../../assets/images/download-icon.svg"
              alt="Icone de download"
            />
          </button>
        </div>
      </div>
    </div>
    <button class="button save" (click)="sendDatas()" style="height: 36px">
      <img
        src="../../../../assets/images/save-icon.svg"
        alt="Icone de salvar"
      />
      <span>Salvar</span>
    </button>
  </div>
</div>

<div class="provider-not-found bounceIn" *ngIf="providerNotFound">
  <p>Fornecedor não registrado</p>
</div>

<app-spinner *ngIf="loading"></app-spinner>
