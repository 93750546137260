<div
  class="form-group"
>
  <app-select-input
    #inputProvider
    [label]="label ? label : 'Fornecedor'"
    [required]="required"
    [disabled]="disabled"
    (changeValue)="changeProviderValue($event)"
    [list]="providerOptionsList"
    [loadMoreButton]="true"
    (loadMore)="loadMoreProviderOptionsList($event)"
    (getSearchOptions)="getProviderSearchOptions($event)"
  ></app-select-input>
</div>

<app-spinner *ngIf="loading"></app-spinner>

<!-- [hidden]="isUserProvider"
  [ngStyle]="{ display: isUserProvider ? 'none' : '' }" -->