<button class="button btn-print" (click)="printForm()">
  Imprimir
</button>
<div class="content">
  <div *ngIf="answer">
    <div style="align-items: center">
      <div style=" display: flex; align-items: center; justify-content: space-between;">
        <img src="../../../assets/images/pmo-logo.png" alt="report-logo" width="150px"/>
        <div>
          <h1>Plano de Manejo Orgânico</h1>
          <h1 style="color: #495729">Produção Primária Vegetal</h1>
        </div>

        <span>{{ answer.title || '-' }}</span>
      </div>
    </div>
    <div *ngIf="answer.steps">
      <div *ngFor="let step of answer.steps; let i = index">
        <div *ngIf="i === 0">
          <div class="section">
            <span>{{ (i + 1) + ".  " + step.title }}</span>
          </div>

          <ng-container *ngIf="step.questions">
            <span>
              <strong>{{ step.questions[0].title + ': ' }}</strong>{{ step.questions[0].value }}
            </span>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let index of [1, 3, 5, 7, 8]">
                  <tr>
                    <td><strong>{{ step.questions[index].title }}:</strong> {{ step.questions[index].value }}</td>
                    <td *ngIf="index < 8">
                      <strong>{{ step.questions[index + 1]?.title }}</strong> {{ step.questions[index].value }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px 0;
              "
            >
              <span>
                <strong>{{ step.questions[10].title }}</strong>{{ step.questions[10].value }}
              </span>
              <span>
                <strong>{{step.questions[11].title + ": "}}</strong>{{ step.questions[11].value  }}
              </span>
            </div>

            <span>
              <strong>{{ step.questions[12].title }}</strong>{{ step.questions[12].value }}
            </span>
            <div
              *ngIf="
                step.questions[12].dynamicMatrix &&
                step.questions[12].dynamicMatrix.columns
              "
            >
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let columnIndex of [0, 2]">
                    <tr>
                      <td>
                        <strong>{{ step.questions[12].dynamicMatrix.columns[columnIndex].title + ":" }}</strong> {{step.questions[12].dynamicMatrix.columns[columnIndex].value}}
                      </td>
                      <td>
                        <strong>{{ step.questions[12].dynamicMatrix.columns[columnIndex + 1]?.title + ":"}}</strong> {{ step.questions[12].dynamicMatrix.columns[columnIndex + 1]?.value}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
        <div *ngIf="i > 0">
          <div class="section">
            <span>{{ (i + 1) + ".  " + step.title }}</span>
          </div>
          <div
          *ngFor="let question of step.questions"
          style="display: flex; flex-direction: column; gap: 12px"
        >
          <div *ngIf="question.type === 'DYNAMIC_MATRIX'">
            <span>{{ question.title }}</span>
            <div *ngIf=" question.dynamicMatrix && question.dynamicMatrix.columns">
              <div>
                <table>
                  <thead>
                    <tr>
                      <th *ngIf="question.dynamicMatrix.headerRows && question.dynamicMatrix.headerRows.length > 0">&nbsp;</th>
                      <th *ngFor=" let column of question.dynamicMatrix.columns">
                        {{ column.title }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="question.dynamicMatrix.rows">
                    <ng-container *ngFor="let columnIndex of [0, 2]">
                      <tr>
                        <td *ngIf="question.dynamicMatrix.headerRows && question.dynamicMatrix.headerRows.length > 0">{{ question.dynamicMatrix.headerRows[columnIndex]}}</td>
                        <td>
                          {{ question.dynamicMatrix.rows[columnIndex] + ":" }}
                        </td>
                        <td>
                          {{ question.dynamicMatrix.rows[columnIndex + 1] + ":" }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <span *ngIf="question.type === 'TEXT'">
            {{ i === 1 ? question.description : question.title }}
          </span>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
