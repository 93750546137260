import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  storage: Storage;
  token: any;
  item: any;
  httpOtions: any;
  user: any;
  constructor(private router: Router, private http: HttpClient) {
    this.storage = window.sessionStorage;
    this.getAuthorization();
  }

  getAuthorization() {
    this.item = this.storage.getItem('token');
    this.token = this.item ? JSON.parse(this.item) : {};
    let user = this.storage.getItem('user');
    this.user = user ? JSON.parse(user) : {};

    this.httpOtions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
  }

  createNewHeaderProcess(body: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };

    return this.http.post<Object>(
      `${environment.api}/protocol-service/register?creationToken=${this.user.customerId}`,
      body,
      httpOptions
    );
  }

  createNewQuestionsProcess(body: any, id: any): Observable<Object> {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let params = new HttpParams().set('protocolId', id);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
    };

    return this.http.put<Object>(
      `${environment.api}/protocol-service/add-param-to-process?`,
      body,
      httpOptions
    );
  }

  getAuditingEnterprises() {
    return this.http.get(
      `${environment.api}/enterprise-service/list?operationalType=AUDITING`,
      this.httpOtions
    );
  }

  getEnterpriseByOperationalType(operationalType: string) {
    return this.http
      .get(
        `${environment.api}/enterprise-service/list?operationalType=${operationalType}`,
        this.httpOtions
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          throw error;
        })
      );
  }

  getEnterprises() {
    return this.http.get(
      `${environment.api}/enterprise-service/list`,
      this.httpOtions
    );
  }

  getEnterprisesProcess() {
    return this.http.get(`${environment.api}/process-service/list`);
  }

  public getEnterpriseByProviderDocument(document: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    let params = new HttpParams().set('document', document);
    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(`${environment.api}/enterprise-service/list`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  getDatasReceivements(
    category: string,
    customerEconomicGroup: string,
    type: string,
    startCompletionDate: string,
    SendCompletionDate: string
  ) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams().set(
      'customerEconomicGroup',
      customerEconomicGroup
    );

    if (category) {
      params = params.set('category', category);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (startCompletionDate) {
      params = params.set('startCompletionDate', startCompletionDate);
    }

    if (SendCompletionDate) {
      params = params.set('SendCompletionDate', SendCompletionDate);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(
        `${environment.api}/enterprise-service/receivementsCalculation`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public getEnterpriseByCustomerId(customerId?: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    let params = new HttpParams().set(
      'customerId',
      customerId ? customerId : this.user.customerId
    );
    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(`${environment.api}/enterprise-service/list`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  public getEnterpriseProjection() {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    }
    return this.http
      .get(
        `${environment.api}/enterprise-service/get-enterprise-projection-by-customerId`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  getEnterpriseByOperationalTypeEconomicGroup(
    corporateName: string,
    operationalType: string,
    economicGroup: string,
    providerDocument?: string
  ) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    let params = new HttpParams();
    if (corporateName) {
      params = params.set('corporateName', corporateName);
    }

    if (economicGroup) {
      params = params.set('economicGroup', economicGroup);
    }

    if (operationalType) {
      params = params.set('operationalType', operationalType);
    }

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(`${environment.api}/enterprise-service/list-paginated`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  findAllByCorporateNameAndOperationalType(options: {
    page?: number;
    size?: number;
    corporateName?: string;
    operationalType?: string;
    economicGroup?: string;
    providerDocument?: string;
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};
    let params = new HttpParams();

    if (options.page === 0 || options.page) {
      params = params.set('page', options.page);
    }
    if (options.size) {
      params = params.set('size', options.size);
    }
    if (options.economicGroup) {
      params = params.set('economicGroup', options.economicGroup);
    }
    if (options.operationalType) {
      params = params.set('operationalType', options.operationalType);
    }
    if (options.providerDocument) {
      params = params.set('providerDocument', options.providerDocument);
    }
    params = params.set(
      'corporateName',
      options.corporateName ? options.corporateName : ''
    );

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(
        `${environment.api}/enterprise-service/find-all-by-corporate-name-and-operational-type`,
        http
      )
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  getEnterpriseByDocument(document: string) {
    this.getAuthorization();
    return this.http.get(
      `${environment.api}/enterprise-service/list?document=${document}`,
      this.httpOtions
    );
  }

  getEnterpriseLaboratory() {
    return this.http.get(
      `${environment.api}/enterprise-service/list?operationalType=LABORATORY`,
      this.httpOtions
    );
  }

  getEnterpriseByCorporateName(name: string) {
    return this.http.get(
      `${environment.api}/enterprise-service/list?corporateName=${name}`,
      this.httpOtions
    );
  }

  getEnterpriseByCorporateNamePaginated(name: string) {
    return this.http.get(
      `${environment.api}/enterprise-service/list-paginated?corporateName=${name}`,
      this.httpOtions
    );
  }

  getEnterpriseByOperationType(operationType: string) {
    return this.http.get(
      `${environment.api}/enterprise-service/list?operationalType=${operationType}`,
      this.httpOtions
    );
  }

  getEnterpriseByCode(code: any) {
    return this.http.get(
      `${environment.api}/enterprise-service/list?enterpriseCode=${code}`,
      this.httpOtions
    );
  }

  public getEnterpriseByEconomicGroup(economicGroup: string) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const user = this.storage.getItem('user');
    const newUser = user ? JSON.parse(user) : {};

    let isUserProvider = null;

    if (newUser.authorities.some((item: string) => item === 'ROLE_PROVIDER')) {
      isUserProvider = true;
    }

    let http = {};

    let params = new HttpParams().set('economicGroup', economicGroup);

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http
      .get(`${environment.api}/enterprise-service/list?`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  getProvidersBlocked(customerId: string, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams().set('customerId', customerId);

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(`${environment.api}/enterprise-service/list-blocked-providers`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }

  listBlockedSifs(customerId: string, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams().set('customerId', customerId);

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http
      .get(`${environment.api}/enterprise-service/list-blocked-sifs`, http)
      .pipe(
        (res) => res,
        (error) => error
      );
  }
}
