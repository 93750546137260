import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { iFilter } from 'src/app/interfaces/enterprise-provider-customer.interface';
import { ClientsService } from 'src/app/services/clients.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-provider-select-filter',
  templateUrl: './provider-select-filter.component.html',
  styleUrls: ['./provider-select-filter.component.scss'],
})
export class ProviderSelectFilterComponent implements OnInit {
  @Input() isUserProvider: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() label!: string;
  @Input() customerDocument!: string | null;
  @Output() handleChangeProviderValue = new EventEmitter();
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  providerOptionsList: Array<{ name: string; value: string }> = [];
  loading: boolean = false;
  providersList: any[] = [];
  localCustomerDocument!: string;
  size: number = 10;

  constructor(
    private fornecedoresService: FornecedoresService,
    private clientsService: ClientsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.customerDocument) {
      this.localCustomerDocument = this.customerDocument;
    }
  }

  changeProviderValue(event: any) {
    if (event) {
      if (this.localCustomerDocument || this.isUserProvider) {
        const provider = this.providersList.find(
          (provider) => provider.document === event
        );
        this.handleChangeProviderValue.emit(provider);
      } else {
        this.handleChangeProviderValue.emit(event);
      }
    }
  }

  loadMoreProviderOptionsList(event: any) {
    this.loading = true;
    this.inputProvider.loadMoreButton = true;
    if (!this.label) {
      this.size = this.size + 10;
      const filter: iFilter = {
        providerCorporateName: this.inputProvider.value,
        page: this.inputProvider.page,
        size: this.size,
      };
      if (!this.customerDocument || this.isUserProvider) {
        this.fornecedoresService.listEnterpriseProvider(filter).subscribe(
          (res: any) => {
            if (this.inputProvider.page === 0) {
              this.providersList = res.content;
            } else {
              res.content.map((item: any) => {
                if (
                  !this.providersList.some((element: any) => element === item)
                ) {
                  this.providersList.push(item);
                }
              });
            }
            if (res.totalItems === this.providersList.length) {
              this.inputProvider.loadMoreButton = false;
            }
            this.inputProvider.page++;
            this.getProviderList();
            this.inputProvider.changeOptionsFilter(this.providerOptionsList);
            this.inputProvider.optionsShow = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      } else {
        this.fornecedoresService
          .listEnterpriseProviderByDocument(this.localCustomerDocument, filter)
          .subscribe({
            next: (res) => {
              this.loading = false;
              if (res && res.content) {
                this.providersList = res.content;
                this.getProviderList();
                this.inputProvider.changeOptionsFilter(
                  this.providerOptionsList
                );
                this.inputProvider.optionsShow = true;
              }
            },
            error: (error) => {
              this.loading = false;
            },
          });
      }
    } else {
      this.clientsService
        .listEnterpriseCustomer(this.inputProvider.value)
        .subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res) {
              this.providersList = res;
              this.getProviderList();
              this.inputProvider.changeOptionsFilter(this.providerOptionsList);
              this.inputProvider.optionsShow = true;
            }
          },
          error: (error) => {
            this.loading = false;
          },
        });
    }
  }

  setValue(value: string) {
    this.inputProvider.value = value;
    this.inputProvider.change = false;
  }

  public getProviderList(): void {
    this.loading = true;
    this.providerOptionsList = [];
    if (this.providersList.length > 0) {
      this.providerOptionsList = this.providersList.map((item: any) => {
        let option = {
          name: `${item.enterpriseCode ? item.enterpriseCode + ' - ' : ''} ${
            item.corporateName
          } - ${formatCpfCnpj(item.document)}`,
          value: item.document,
        };
        return option;
      });
      this.inputProvider.changeOptionsFilter(this.providerOptionsList);
    }
    this.loading = false;
  }

  getProviderSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      const filter: iFilter = {
        providerCorporateName: event,
        page: this.inputProvider.page,
        size: this.size,
      };
      if (!this.label) {
        if (!this.localCustomerDocument || this.isUserProvider) {
          this.fornecedoresService.listEnterpriseProvider(filter).subscribe({
            next: (res) => {
              this.loading = false;
              if (res && res.content) {
                this.providersList = res.content;
                this.getProviderList();
                this.inputProvider.changeOptionsFilter(
                  this.providerOptionsList
                );
                this.inputProvider.optionsShow = true;
              }
            },
            error: (error) => {
              this.loading = false;              
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  error: true,
                  errorText: 'Nenhum fornecedor encontrado'
                }
              })
            },
          });
        } else {
          this.fornecedoresService
            .listEnterpriseProviderByDocument(
              this.localCustomerDocument,
              filter
            )
            .subscribe({
              next: (res) => {
                this.loading = false;
                if (res && res.content) {
                  this.providersList = res.content;
                  this.getProviderList();
                  this.inputProvider.changeOptionsFilter(
                    this.providerOptionsList
                  );
                  this.inputProvider.optionsShow = true;
                }
              },
              error: (error) => {
                this.loading = false;
              },
            });
        }
      } else {
        this.clientsService.listEnterpriseCustomer(event).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res) {
              this.providersList = res;
              this.getProviderList();
              this.inputProvider.changeOptionsFilter(this.providerOptionsList);
              this.inputProvider.optionsShow = true;
            }
          },
          error: (error) => {
            this.loading = false;
          },
        });
      }
    }
  }

  handleChanged() {
    this.inputProvider.change = true;
  }

  cleanFilter() {
    this.inputProvider.value = '';
  }
}
