<aside>
  <nav>
    <ul>
      <!-- Menu Usuário Fornecedor -->
      <li>
        <div class="arrow" (click)="toggleArrowSide()" [ngStyle]="{ left: isSidebarPinned ? '22px' : '208px' }">
          <svg [style.transform]="rotationArrow()" width="16" height="15" viewBox="0 0 16 15" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.8647 0.00176811C15.1295 0.0013113 15.3861 0.0826864 15.59 0.231768C15.7048 0.315717 15.7996 0.418818 15.8692 0.535166C15.9387 0.651513 15.9815 0.778819 15.9952 0.909796C16.0089 1.04077 15.9932 1.17284 15.9489 1.29845C15.9047 1.42405 15.8328 1.54072 15.7374 1.64177L10.6603 7.00177L15.556 12.3718C15.6502 12.4741 15.7205 12.5918 15.7629 12.7181C15.8053 12.8444 15.819 12.9769 15.8032 13.108C15.7874 13.239 15.7424 13.366 15.6708 13.4816C15.5992 13.5972 15.5025 13.6992 15.386 13.7818C15.2688 13.8728 15.1315 13.9415 14.9827 13.9835C14.834 14.0255 14.677 14.0399 14.5216 14.0258C14.3663 14.0117 14.2159 13.9694 14.0799 13.9016C13.9439 13.8338 13.8253 13.7419 13.7315 13.6318L8.2578 7.63177C8.09112 7.45284 8 7.22839 8 6.99677C8 6.76514 8.09112 6.5407 8.2578 6.36177L13.9241 0.361769C14.0378 0.240751 14.1822 0.145087 14.3457 0.0825386C14.5091 0.01999 14.6869 -0.0076828 14.8647 0.00176811Z"
              fill="url(#paint0_linear_341_122)" />
            <path
              d="M6.86474 0.00176811C7.12953 0.0013113 7.38614 0.0826864 7.59003 0.231768C7.70478 0.315717 7.79964 0.418818 7.86917 0.535166C7.93869 0.651513 7.98153 0.778819 7.99521 0.909796C8.0089 1.04077 7.99317 1.17284 7.94892 1.29845C7.90467 1.42405 7.83278 1.54072 7.73736 1.64177L2.66033 7.00177L7.55603 12.3718C7.65017 12.4741 7.72047 12.5918 7.76289 12.7181C7.80531 12.8444 7.81901 12.9769 7.80322 13.108C7.78742 13.239 7.74243 13.366 7.67084 13.4816C7.59925 13.5972 7.50246 13.6992 7.38604 13.7818C7.26879 13.8728 7.13147 13.9415 6.98272 13.9835C6.83396 14.0255 6.67698 14.0399 6.52162 14.0258C6.36625 14.0117 6.21587 13.9694 6.0799 13.9016C5.94392 13.8338 5.8253 13.7419 5.73147 13.6318L0.257804 7.63177C0.0911217 7.45284 0 7.22839 0 6.99677C0 6.76514 0.0911217 6.5407 0.257804 6.36177L5.92413 0.361769C6.03782 0.240751 6.18223 0.145087 6.34566 0.0825386C6.50909 0.01999 6.68692 -0.0076828 6.86474 0.00176811Z"
              fill="#9F9F9F" />
            <defs>
              <linearGradient id="paint0_linear_341_122" x1="15.8251" y1="0.20834" x2="0.690979" y2="14.7237"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#bfbfbf" />
                <stop offset="1" stop-color="#a6a6a6" />
              </linearGradient>
              <linearGradient id="paint1_linear_341_122" x1="15.8251" y1="0.20834" x2="0.690979" y2="14.7237"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#bfbfbf" />
                <stop offset="1" stop-color="#a6a6a6" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </li>
      <li>
        <a routerLink="/minha-empresa/home" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="icon">
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24.8809 11.8086L13.618 0.553933C13.5369 0.472678 13.4406 0.408214 13.3345 0.364231C13.2285 0.320247 13.1148 0.297607 13 0.297607C12.8852 0.297607 12.7716 0.320247 12.6655 0.364231C12.5595 0.408214 12.4632 0.472678 12.3821 0.553933L1.11917 11.8086C0.79104 12.1367 0.605103 12.5824 0.605103 13.0473C0.605103 14.0125 1.38987 14.7973 2.3551 14.7973H3.54182V22.8282C3.54182 23.3121 3.93284 23.7032 4.41682 23.7032H11.25V17.5782H14.3125V23.7032H21.5832C22.0672 23.7032 22.4582 23.3121 22.4582 22.8282V14.7973H23.6449C24.1098 14.7973 24.5555 14.6141 24.8836 14.2832C25.5645 13.5996 25.5645 12.4922 24.8809 11.8086Z"
                fill="#53565A" />
            </svg>
          </span>
          <span class="item">Home</span>
        </a>
      </li>
      <!-- <li >
        <a routerLink="/minha-empresa/meus-dados" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path
                d="M15 5C9.477 5 5 9.477 5 15C5 20.523 9.477 25 15 25C20.523 25 25 20.523 25 15C25 9.477 20.523 5 15 5Z"
                stroke="#53565A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7 21C7 21 9.30701 18.2338 14.9995 18.2338C20.692 18.2338 23 21 23 21M14.9995 14.8318C15.823 14.8318 16.6127 14.5246 17.195 13.9778C17.7773 13.431 18.1045 12.6893 18.1045 11.9159C18.1045 11.1426 17.7773 10.4009 17.195 9.85405C16.6127 9.30721 15.823 9 14.9995 9C14.176 9 13.3862 9.30721 12.8039 9.85405C12.2216 10.4009 11.8945 11.1426 11.8945 11.9159C11.8945 12.6893 12.2216 13.431 12.8039 13.9778C13.3862 14.5246 14.176 14.8318 14.9995 14.8318V14.8318Z"
                stroke="#53565A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span class="item">Meus Dados</span>
        </a>
      </li> -->
      <li>
        <a routerLink="/minha-empresa/recebimento" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg width="28" height="28" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M42.5 32.2105V17.1052H1.5V40.8421C1.5 41.4144 1.72735 41.9632 2.13203 42.3679C2.53672 42.7726 3.08559 43 3.65789 43H22"
                stroke="#53565A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1.5 7.39471C1.5 6.8224 1.72735 6.27353 2.13203 5.86885C2.53672 5.46417 3.08559 5.23682 3.65789 5.23682H40.3421C40.9144 5.23682 41.4633 5.46417 41.868 5.86885C42.2727 6.27353 42.5 6.8224 42.5 7.39471V17.1052H1.5V7.39471Z"
                stroke="#53565A" stroke-width="3" stroke-linejoin="round" />
              <path d="M13.3682 2V10.6316M30.6313 2V10.6316" stroke="#53565A" stroke-width="3" stroke-linecap="round" />
              <path
                d="M28.474 38.6842C32.6452 38.6842 36.0267 35.3028 36.0267 31.1316C36.0267 26.9604 32.6452 23.579 28.474 23.579C24.3028 23.579 20.9214 26.9604 20.9214 31.1316C20.9214 35.3028 24.3028 38.6842 28.474 38.6842Z"
                stroke="#53565A" stroke-width="3" />
              <path d="M34.9473 36.5264L41.421 41.9211" stroke="#53565A" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <span class="item">Recebimento</span>
        </a>
      </li>
      <li>
        <a routerLink="/minha-empresa/expedicao" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.1579 36C9.28947 36 8.54632 35.6869 7.92842 35.0608C7.30947 34.4336 7 33.68 7 32.8V7.2C7 6.32 7.30947 5.5664 7.92842 4.9392C8.54632 4.31307 9.28947 4 10.1579 4H22.7895L32.2632 13.6V18.56L19.6316 31.32V36H10.1579ZM22.7895 36V32.6L30.9211 24.32L34.3158 27.68L26.1447 36H22.7895ZM35.4605 26.56L32.1053 23.16L33.2105 22.04C33.5263 21.72 33.9079 21.56 34.3553 21.56C34.8026 21.56 35.1711 21.72 35.4605 22.04L36.5658 23.2C36.8553 23.52 37 23.8997 37 24.3392C37 24.7797 36.8553 25.1467 36.5658 25.44L35.4605 26.56ZM21.2105 15.2H29.1053L21.2105 7.2V15.2Z"
                fill="#53565A" />
            </svg>
          </span>
          <span class="item">Expedição</span>
        </a>
      </li>

      <li>
        <a routerLink="/minha-empresa/processos" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: false }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.6667 16H18.6667V18.6667H10.6667V16ZM8 5.33333H5.33333V8H8V5.33333ZM10.6667 13.3333H18.6667V10.6667H10.6667V13.3333ZM10.6667 8H18.6667V5.33333H10.6667V8ZM8 10.6667H5.33333V13.3333H8V10.6667ZM24 2.66667V21.3333C24 22.8 22.8 24 21.3333 24H2.66667C1.2 24 0 22.8 0 21.3333V2.66667C0 1.2 1.2 0 2.66667 0H21.3333C22.8 0 24 1.2 24 2.66667ZM21.3333 2.66667H2.66667V21.3333H21.3333V2.66667ZM8 16H5.33333V18.6667H8V16Z"
                fill="#53565A" />
            </svg>
          </span>
          <span class="item">Processos</span>
        </a>
      </li>
      <li>
        <a routerLink="/minha-empresa/processos/66daa38a61fa866504ba8321/response-list" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">

          <span class="item">Produção Animal</span>
        </a>
      </li>
      <li>
        <a routerLink="/minha-empresa/processos/66daa37461fa866504ba82fb/response-list" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">

          <span class="item">Produção Vegetal</span>
        </a>
      </li>
      <li>
        <a routerLink="/minha-empresa/consulta-fornecedores" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path
                d="M10.215 5L8.42663 6.65039L9.5951 7.72444L11.378 6.07506L10.215 5ZM12.0747 6.72294L11.5316 7.22572L18.2791 13.4681L18.8222 12.9658L12.0747 6.72294ZM20.8191 7.6392C20.6161 7.6392 20.4131 7.64428 20.2211 7.65442C18.4711 7.74473 17.1107 8.25106 15.9257 8.9933L19.5189 12.3215C19.7109 12.6918 22.3716 17.2021 23.1121 18.577C22.58 18.2523 16.3427 15.259 16.3427 15.259L15.5033 14.4878V21.6059C16.8144 20.8651 18.2517 20.2005 20.0895 20.023C22.1466 19.82 24.6701 20.2411 28 21.7023V9.35352C25.1638 8.18713 22.8817 7.68486 21.022 7.64428C20.9507 7.64428 20.8849 7.6387 20.8191 7.6392ZM8.15782 7.69146C6.46271 7.84315 4.43515 8.35405 2 9.35352V21.7023C5.33537 20.2411 7.8561 19.82 9.91328 20.023C11.7236 20.2868 13.1005 20.8246 14.4994 21.6059V13.5594C12.3819 11.6061 10.2699 9.64778 8.15782 7.69146ZM10.8349 7.87157L10.2918 8.37333L17.0394 14.6147L17.5824 14.1124L10.8349 7.87157ZM19.3873 13.737L17.8787 15.1322L20.9014 16.5324L19.3873 13.737ZM8.84355 20.921C7.08809 20.9362 4.90199 21.4182 2 22.7474V24.9036C5.33537 23.4425 7.8561 23.0214 9.91328 23.2243C10.9336 23.3258 11.8278 23.5744 12.6452 23.8991C12.3874 24.2085 12.135 24.5738 11.8827 25H18.3121C17.9829 24.584 17.6647 24.2187 17.3411 23.9041C18.1639 23.5744 19.0636 23.3258 20.0895 23.2243C22.1466 23.0214 24.6701 23.4425 28 24.9036V22.7474C25.087 21.4131 22.8927 20.926 21.1318 20.921H20.9617C20.6984 20.926 20.446 20.9412 20.1992 20.9666C18.334 21.1493 16.9296 21.8798 15.5033 22.7119C15.1906 22.6713 14.7902 22.6206 14.4994 22.6662C13.0073 21.8443 11.3067 21.1239 9.80905 20.9666C9.50185 20.9362 9.17818 20.921 8.84355 20.921Z"
                fill="#53565A" />
            </svg>
          </span>
          <span class="item">Meus Fornecedores</span>
        </a>
      </li>
      <li>
        <a routerLink="/minha-empresa/produtos" routerLinkActive="active" ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'pointer-events': needUpdateData? 'none' : 'all'}">
          <span class="icon">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25 7L13 1L1 7V19L13 25L25 19V7Z" stroke="#53565A" stroke-width="2" stroke-linejoin="round" />
              <path d="M1 7L13 13M13 25V13M25 7L13 13M19 4L7 10" stroke="#53565A" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span class="item">Produtos</span>
        </a>
      </li>

    </ul>
  </nav>
