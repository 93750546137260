import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { iFilter } from 'src/app/interfaces/enterprise-provider-customer.interface';

@Component({
  selector: 'app-provider-select-filter',
  templateUrl: './provider-select-filter.component.html',
  styleUrls: ['./provider-select-filter.component.scss'],
})
export class ProviderSelectFilterComponent {
  @Input() isUserProvider: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() handleChangeProviderValue = new EventEmitter();
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  providerOptionsList: Array<{ name: string; value: string }> = [];
  loading: boolean = false;
  providersList: any[] = [];

  constructor(private fornecedoresService: FornecedoresService) {}

  changeProviderValue(event: any) {
    if (event) {
      this.handleChangeProviderValue.emit(event);
    }
  }

  loadMoreProviderOptionsList(event: any) {
    this.loading = true;
    this.inputProvider.loadMoreButton = true;
    const filter: iFilter = {
      providerCorporateName: this.inputProvider.value,
      page: this.inputProvider.page,
      size: 10,
    };
    this.fornecedoresService.listEnterpriseProvider(filter).subscribe(
      (res: any) => {
        if (this.inputProvider.page === 0) {
          this.providersList = res.content;
        } else {
          res.content.map((item: any) => {
            if (!this.providersList.some((element: any) => element === item)) {
              this.providersList.push(item);
            }
          });
        }
        if (res.totalItems === this.providersList.length) {
          this.inputProvider.loadMoreButton = false;
        }
        this.inputProvider.page++;
        this.getProviderList();
        this.inputProvider.changeOptionsFilter(this.providerOptionsList);
        this.inputProvider.optionsShow = true;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setValue(value: string) {
    this.inputProvider.value = value;
    this.inputProvider.change = false;
  }

  public getProviderList(): void {
    this.loading = true;
    this.providerOptionsList = [];
    if (this.providersList.length > 0) {
      this.providerOptionsList = this.providersList.map((item: any) => {
        let option = {
          name: `${item.enterpriseCode} - ${
            item.corporateName
          } - ${formatCpfCnpj(item.document)}`,
          value: item.document,
        };
        return option;
      });
      this.inputProvider.changeOptionsFilter(this.providerOptionsList);
    }
    this.loading = false;
  }

  getProviderSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      const filter: iFilter = {
        providerCorporateName: event,
        page: this.inputProvider.page,
        size: 10,
      };
      this.fornecedoresService.listEnterpriseProvider(filter).subscribe({
        next: (res: any) => {
          this.loading = false;
          if (res && res.content) {
            this.providersList = res.content;
            this.getProviderList();
            this.inputProvider.changeOptionsFilter(this.providerOptionsList);
            this.inputProvider.optionsShow = true;
          }
        },
        error: (error) => {
          this.loading = false;
        },
      });
    }
  }

  handleChanged() {
    this.inputProvider.change = true;
  }

  cleanFilter() {
    this.inputProvider.value = '';
  }
}
