<div class="modal-container" *ngIf="default">
  <button type="button" (click)="closeModal()" class="button-close">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
        fill="#b81417"
      />
    </svg>
  </button>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="!data.delete"
  >
    <circle cx="70" cy="70" r="70" fill="#AE1518" />
    <path
      d="M45.5 101.5H94.5C96.3565 101.5 98.137 100.763 99.4497 99.4497C100.763 98.137 101.5 96.3565 101.5 94.5V56L84 38.5H45.5C43.6435 38.5 41.863 39.2375 40.5503 40.5503C39.2375 41.863 38.5 43.6435 38.5 45.5V94.5C38.5 96.3565 39.2375 98.137 40.5503 99.4497C41.863 100.763 43.6435 101.5 45.5 101.5ZM52.5 45.5H66.5V52.5H73.5V45.5H80.5V59.5H52.5V45.5ZM52.5 73.5H87.5V94.5H52.5V73.5Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="137.921"
        x2="150.433"
        y2="12.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#e6191d" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="data.delete"
  >
    <circle cx="70" cy="70" r="70" fill="#88191d" />
    <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_0_1)" />
    <path
      d="M63.7 51.1H76.3C76.3 49.4291 75.6363 47.8267 74.4548 46.6452C73.2733 45.4637 71.6709 44.8 70 44.8C68.3291 44.8 66.7267 45.4637 65.5452 46.6452C64.3638 47.8267 63.7 49.4291 63.7 51.1ZM57.4 51.1C57.4 47.7583 58.7275 44.5534 61.0905 42.1905C63.4534 39.8275 66.6583 38.5 70 38.5C73.3417 38.5 76.5466 39.8275 78.9095 42.1905C81.2725 44.5534 82.6 47.7583 82.6 51.1H98.35C99.1854 51.1 99.9867 51.4319 100.577 52.0226C101.168 52.6134 101.5 53.4146 101.5 54.25C101.5 55.0854 101.168 55.8866 100.577 56.4774C99.9867 57.0681 99.1854 57.4 98.35 57.4H95.5717L92.7808 89.971C92.5125 93.1162 91.0734 96.0461 88.7483 98.181C86.4232 100.316 83.3815 101.5 80.2249 101.5H59.7751C56.6185 101.5 53.5768 100.316 51.2517 98.181C48.9266 96.0461 47.4875 93.1162 47.2192 89.971L44.4283 57.4H41.65C40.8146 57.4 40.0134 57.0681 39.4226 56.4774C38.8319 55.8866 38.5 55.0854 38.5 54.25C38.5 53.4146 38.8319 52.6134 39.4226 52.0226C40.0134 51.4319 40.8146 51.1 41.65 51.1H57.4ZM79.45 70C79.45 69.1646 79.1181 68.3634 78.5274 67.7726C77.9366 67.1819 77.1354 66.85 76.3 66.85C75.4646 66.85 74.6634 67.1819 74.0726 67.7726C73.4819 68.3634 73.15 69.1646 73.15 70V82.6C73.15 83.4354 73.4819 84.2367 74.0726 84.8274C74.6634 85.4181 75.4646 85.75 76.3 85.75C77.1354 85.75 77.9366 85.4181 78.5274 84.8274C79.1181 84.2367 79.45 83.4354 79.45 82.6V70ZM63.7 66.85C62.8646 66.85 62.0634 67.1819 61.4726 67.7726C60.8819 68.3634 60.55 69.1646 60.55 70V82.6C60.55 83.4354 60.8819 84.2367 61.4726 84.8274C62.0634 85.4181 62.8646 85.75 63.7 85.75C64.5354 85.75 65.3366 85.4181 65.9274 84.8274C66.5181 84.2367 66.85 83.4354 66.85 82.6V70C66.85 69.1646 66.5181 68.3634 65.9274 67.7726C65.3366 67.1819 64.5354 66.85 63.7 66.85Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="137.921"
        x2="150.433"
        y2="12.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#e6191d" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>
  <div class="text-area">
    {{ data.text ? data.text : "Deseja Salvar?" }}
  </div>
  <div class="buttons-area">
    <button (click)="submit()">Sim</button>
    <button class="close-button" (click)="closeModal()">Não</button>
  </div>
</div>

<div class="modal-container" *ngIf="confirm">
  <button type="button" (click)="closeModal()" class="button-close">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
        fill="#b81417"
      />
    </svg>
  </button>
  <svg
    width="140"
    height="149"
    viewBox="0 0 140 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="!data.delete"
  >
    <circle cx="70" cy="79" r="70" fill="#AE1518" />
    <path
      d="M45.5 110.5H94.5C96.3565 110.5 98.137 109.763 99.4497 108.45C100.763 107.137 101.5 105.357 101.5 103.5V65L84 47.5H45.5C43.6435 47.5 41.863 48.2375 40.5503 49.5503C39.2375 50.863 38.5 52.6435 38.5 54.5V103.5C38.5 105.357 39.2375 107.137 40.5503 108.45C41.863 109.763 43.6435 110.5 45.5 110.5ZM52.5 54.5H66.5V61.5H73.5V54.5H80.5V68.5H52.5V54.5ZM52.5 82.5H87.5V103.5H52.5V82.5Z"
      fill="white"
    />
    <circle cx="116" cy="24" r="24" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M116 0C102.745 0 92 10.7455 92 24C92 37.2545 102.745 48 116 48C129.255 48 140 37.2545 140 24C140 10.7455 129.255 0 116 0ZM126.403 19.9418C126.594 19.7229 126.74 19.4678 126.832 19.1916C126.923 18.9155 126.959 18.6238 126.936 18.3338C126.913 18.0438 126.832 17.7612 126.699 17.5028C126.565 17.2444 126.381 17.0154 126.158 16.8291C125.934 16.6429 125.676 16.5032 125.397 16.4183C125.119 16.3334 124.827 16.3051 124.537 16.3349C124.248 16.3647 123.967 16.4521 123.712 16.5919C123.457 16.7318 123.232 16.9212 123.052 17.1491L113.67 28.4051L108.815 23.5484C108.404 23.1509 107.853 22.931 107.281 22.936C106.709 22.941 106.161 23.1704 105.757 23.5749C105.352 23.9795 105.123 24.5267 105.118 25.0988C105.113 25.6708 105.333 26.222 105.73 26.6335L112.276 33.1789C112.49 33.3932 112.747 33.5604 113.029 33.67C113.312 33.7795 113.614 33.829 113.917 33.8152C114.22 33.8015 114.516 33.7248 114.788 33.5901C115.059 33.4553 115.3 33.2655 115.494 33.0327L126.403 19.9418Z"
      fill="#00D68F"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="146.921"
        x2="150.433"
        y2="21.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>
  <div class="action-icon">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="data.delete"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 0C10.7455 0 0 10.7455 0 24C0 37.2545 10.7455 48 24 48C37.2545 48 48 37.2545 48 24C48 10.7455 37.2545 0 24 0ZM34.4029 19.9418C34.5945 19.7229 34.7403 19.4678 34.8318 19.1916C34.9234 18.9155 34.9587 18.6238 34.9359 18.3338C34.913 18.0438 34.8324 17.7612 34.6987 17.5028C34.565 17.2444 34.3811 17.0154 34.1576 16.8291C33.9341 16.6429 33.6756 16.5032 33.3973 16.4183C33.119 16.3334 32.8266 16.3051 32.5372 16.3349C32.2478 16.3647 31.9673 16.4521 31.7122 16.5919C31.457 16.7318 31.2325 16.9212 31.0516 17.1491L21.6698 28.4051L16.8153 23.5484C16.4038 23.1509 15.8526 22.931 15.2806 22.936C14.7085 22.941 14.1613 23.1704 13.7568 23.5749C13.3522 23.9795 13.1228 24.5267 13.1178 25.0988C13.1128 25.6708 13.3327 26.222 13.7302 26.6335L20.2756 33.1789C20.49 33.3932 20.7467 33.5604 21.0293 33.67C21.3119 33.7795 21.6142 33.829 21.917 33.8152C22.2197 33.8015 22.5163 33.7248 22.7878 33.5901C23.0593 33.4553 23.2997 33.2655 23.4938 33.0327L34.4029 19.9418Z"
        fill="#00D68F"
      />
    </svg>
  </div>

  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="data.delete"
  >
    <circle cx="70" cy="70" r="70" fill="#88191d" />
    <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_0_1)" />
    <path
      d="M63.7 51.1H76.3C76.3 49.4291 75.6363 47.8267 74.4548 46.6452C73.2733 45.4637 71.6709 44.8 70 44.8C68.3291 44.8 66.7267 45.4637 65.5452 46.6452C64.3638 47.8267 63.7 49.4291 63.7 51.1ZM57.4 51.1C57.4 47.7583 58.7275 44.5534 61.0905 42.1905C63.4534 39.8275 66.6583 38.5 70 38.5C73.3417 38.5 76.5466 39.8275 78.9095 42.1905C81.2725 44.5534 82.6 47.7583 82.6 51.1H98.35C99.1854 51.1 99.9867 51.4319 100.577 52.0226C101.168 52.6134 101.5 53.4146 101.5 54.25C101.5 55.0854 101.168 55.8866 100.577 56.4774C99.9867 57.0681 99.1854 57.4 98.35 57.4H95.5717L92.7808 89.971C92.5125 93.1162 91.0734 96.0461 88.7483 98.181C86.4232 100.316 83.3815 101.5 80.2249 101.5H59.7751C56.6185 101.5 53.5768 100.316 51.2517 98.181C48.9266 96.0461 47.4875 93.1162 47.2192 89.971L44.4283 57.4H41.65C40.8146 57.4 40.0134 57.0681 39.4226 56.4774C38.8319 55.8866 38.5 55.0854 38.5 54.25C38.5 53.4146 38.8319 52.6134 39.4226 52.0226C40.0134 51.4319 40.8146 51.1 41.65 51.1H57.4ZM79.45 70C79.45 69.1646 79.1181 68.3634 78.5274 67.7726C77.9366 67.1819 77.1354 66.85 76.3 66.85C75.4646 66.85 74.6634 67.1819 74.0726 67.7726C73.4819 68.3634 73.15 69.1646 73.15 70V82.6C73.15 83.4354 73.4819 84.2367 74.0726 84.8274C74.6634 85.4181 75.4646 85.75 76.3 85.75C77.1354 85.75 77.9366 85.4181 78.5274 84.8274C79.1181 84.2367 79.45 83.4354 79.45 82.6V70ZM63.7 66.85C62.8646 66.85 62.0634 67.1819 61.4726 67.7726C60.8819 68.3634 60.55 69.1646 60.55 70V82.6C60.55 83.4354 60.8819 84.2367 61.4726 84.8274C62.0634 85.4181 62.8646 85.75 63.7 85.75C64.5354 85.75 65.3366 85.4181 65.9274 84.8274C66.5181 84.2367 66.85 83.4354 66.85 82.6V70C66.85 69.1646 66.5181 68.3634 65.9274 67.7726C65.3366 67.1819 64.5354 66.85 63.7 66.85Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="137.921"
        x2="150.433"
        y2="12.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>

  <div class="text-area">
    {{ data.successText ? data.successText : "Cadastro Salvo!" }}
  </div>
  <div class="buttons-area">
    <button class="full-button" (click)="closeModal()">Confirmar</button>
  </div>
</div>

<div class="modal-container" *ngIf="error">
  <button type="button" (click)="closeModal()" class="button-close">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
        fill="#b81417"
      />
    </svg>
  </button>
  <svg
    width="140"
    height="149"
    viewBox="0 0 140 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="!data.delete"
  >
    <circle cx="70" cy="79" r="70" fill="#AE1518" />
    <path
      d="M45.5 110.5H94.5C96.3565 110.5 98.137 109.763 99.4497 108.45C100.763 107.137 101.5 105.357 101.5 103.5V65L84 47.5H45.5C43.6435 47.5 41.863 48.2375 40.5503 49.5503C39.2375 50.863 38.5 52.6435 38.5 54.5V103.5C38.5 105.357 39.2375 107.137 40.5503 108.45C41.863 109.763 43.6435 110.5 45.5 110.5ZM52.5 54.5H66.5V61.5H73.5V54.5H80.5V68.5H52.5V54.5ZM52.5 82.5H87.5V103.5H52.5V82.5Z"
      fill="white"
    />
    <circle cx="116" cy="24" r="24" fill="white" />
    <path
      d="M116 0C102.728 0 92 10.728 92 24C92 37.272 102.728 48 116 48C129.272 48 140 37.272 140 24C140 10.728 129.272 0 116 0ZM126.32 34.32C126.098 34.5425 125.834 34.719 125.544 34.8394C125.254 34.9599 124.942 35.0219 124.628 35.0219C124.314 35.0219 124.002 34.9599 123.712 34.8394C123.422 34.719 123.158 34.5425 122.936 34.32L116 27.384L109.064 34.32C108.615 34.7687 108.007 35.0209 107.372 35.0209C106.737 35.0209 106.129 34.7687 105.68 34.32C105.231 33.8713 104.979 33.2626 104.979 32.628C104.979 32.3138 105.041 32.0026 105.161 31.7123C105.282 31.422 105.458 31.1582 105.68 30.936L112.616 24L105.68 17.064C105.231 16.6153 104.979 16.0066 104.979 15.372C104.979 14.7374 105.231 14.1287 105.68 13.68C106.129 13.2313 106.737 12.9792 107.372 12.9792C108.007 12.9792 108.615 13.2313 109.064 13.68L116 20.616L122.936 13.68C123.158 13.4578 123.422 13.2815 123.712 13.1613C124.003 13.041 124.314 12.9792 124.628 12.9792C124.942 12.9792 125.253 13.041 125.544 13.1613C125.834 13.2815 126.098 13.4578 126.32 13.68C126.542 13.9022 126.718 14.166 126.839 14.4563C126.959 14.7466 127.021 15.0578 127.021 15.372C127.021 15.6862 126.959 15.9974 126.839 16.2877C126.718 16.578 126.542 16.8418 126.32 17.064L119.384 24L126.32 30.936C127.232 31.848 127.232 33.384 126.32 34.32Z"
      fill="#FF3D71"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="146.921"
        x2="150.433"
        y2="21.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>

  <div class="action-icon">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="data.delete"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M24 0C10.728 0 0 10.728 0 24C0 37.272 10.728 48 24 48C37.272 48 48 37.272 48 24C48 10.728 37.272 0 24 0ZM34.32 34.32C34.098 34.5425 33.8342 34.719 33.5439 34.8394C33.2536 34.9599 32.9423 35.0219 32.628 35.0219C32.3137 35.0219 32.0024 34.9599 31.7121 34.8394C31.4218 34.719 31.158 34.5425 30.936 34.32L24 27.384L17.064 34.32C16.6153 34.7687 16.0066 35.0209 15.372 35.0209C14.7374 35.0209 14.1287 34.7687 13.68 34.32C13.2313 33.8713 12.9792 33.2626 12.9792 32.628C12.9792 32.3138 13.041 32.0026 13.1613 31.7123C13.2815 31.422 13.4578 31.1582 13.68 30.936L20.616 24L13.68 17.064C13.2313 16.6153 12.9792 16.0066 12.9792 15.372C12.9792 14.7374 13.2313 14.1287 13.68 13.68C14.1287 13.2313 14.7374 12.9792 15.372 12.9792C16.0066 12.9792 16.6153 13.2313 17.064 13.68L24 20.616L30.936 13.68C31.1582 13.4578 31.422 13.2815 31.7123 13.1613C32.0026 13.041 32.3138 12.9792 32.628 12.9792C32.9422 12.9792 33.2534 13.041 33.5437 13.1613C33.834 13.2815 34.0978 13.4578 34.32 13.68C34.5422 13.9022 34.7185 14.166 34.8387 14.4563C34.959 14.7466 35.0209 15.0578 35.0209 15.372C35.0209 15.6862 34.959 15.9974 34.8387 16.2877C34.7185 16.578 34.5422 16.8418 34.32 17.064L27.384 24L34.32 30.936C35.232 31.848 35.232 33.384 34.32 34.32Z"
        fill="#FF3D71"
      />
    </svg>
  </div>

  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="data.delete"
  >
    <circle cx="70" cy="70" r="70" fill="#88191d" />
    <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_0_1)" />
    <path
      d="M63.7 51.1H76.3C76.3 49.4291 75.6363 47.8267 74.4548 46.6452C73.2733 45.4637 71.6709 44.8 70 44.8C68.3291 44.8 66.7267 45.4637 65.5452 46.6452C64.3638 47.8267 63.7 49.4291 63.7 51.1ZM57.4 51.1C57.4 47.7583 58.7275 44.5534 61.0905 42.1905C63.4534 39.8275 66.6583 38.5 70 38.5C73.3417 38.5 76.5466 39.8275 78.9095 42.1905C81.2725 44.5534 82.6 47.7583 82.6 51.1H98.35C99.1854 51.1 99.9867 51.4319 100.577 52.0226C101.168 52.6134 101.5 53.4146 101.5 54.25C101.5 55.0854 101.168 55.8866 100.577 56.4774C99.9867 57.0681 99.1854 57.4 98.35 57.4H95.5717L92.7808 89.971C92.5125 93.1162 91.0734 96.0461 88.7483 98.181C86.4232 100.316 83.3815 101.5 80.2249 101.5H59.7751C56.6185 101.5 53.5768 100.316 51.2517 98.181C48.9266 96.0461 47.4875 93.1162 47.2192 89.971L44.4283 57.4H41.65C40.8146 57.4 40.0134 57.0681 39.4226 56.4774C38.8319 55.8866 38.5 55.0854 38.5 54.25C38.5 53.4146 38.8319 52.6134 39.4226 52.0226C40.0134 51.4319 40.8146 51.1 41.65 51.1H57.4ZM79.45 70C79.45 69.1646 79.1181 68.3634 78.5274 67.7726C77.9366 67.1819 77.1354 66.85 76.3 66.85C75.4646 66.85 74.6634 67.1819 74.0726 67.7726C73.4819 68.3634 73.15 69.1646 73.15 70V82.6C73.15 83.4354 73.4819 84.2367 74.0726 84.8274C74.6634 85.4181 75.4646 85.75 76.3 85.75C77.1354 85.75 77.9366 85.4181 78.5274 84.8274C79.1181 84.2367 79.45 83.4354 79.45 82.6V70ZM63.7 66.85C62.8646 66.85 62.0634 67.1819 61.4726 67.7726C60.8819 68.3634 60.55 69.1646 60.55 70V82.6C60.55 83.4354 60.8819 84.2367 61.4726 84.8274C62.0634 85.4181 62.8646 85.75 63.7 85.75C64.5354 85.75 65.3366 85.4181 65.9274 84.8274C66.5181 84.2367 66.85 83.4354 66.85 82.6V70C66.85 69.1646 66.5181 68.3634 65.9274 67.7726C65.3366 67.1819 64.5354 66.85 63.7 66.85Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="137.921"
        x2="150.433"
        y2="12.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>

  <div class="text-area">
    {{ data.errorText ? data.errorText : "Falha no envio" }}
  </div>
  <div class="buttons-area">
    <button class="full-button" (click)="closeModal()">Confirmar</button>
  </div>
</div>

<div class="modal-container" *ngIf="email">
  <button type="button" (click)="closeModal()" class="button-close">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
        fill="#b81417"
      />
    </svg>
  </button>
  <svg
    width="140"
    height="149"
    viewBox="0 0 140 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="!data.delete"
  >
    <circle cx="70" cy="79" r="70" fill="#AE1518" />
    <path
      d="M45.5 110.5H94.5C96.3565 110.5 98.137 109.763 99.4497 108.45C100.763 107.137 101.5 105.357 101.5 103.5V65L84 47.5H45.5C43.6435 47.5 41.863 48.2375 40.5503 49.5503C39.2375 50.863 38.5 52.6435 38.5 54.5V103.5C38.5 105.357 39.2375 107.137 40.5503 108.45C41.863 109.763 43.6435 110.5 45.5 110.5ZM52.5 54.5H66.5V61.5H73.5V54.5H80.5V68.5H52.5V54.5ZM52.5 82.5H87.5V103.5H52.5V82.5Z"
      fill="white"
    />
    <circle cx="116" cy="24" r="24" fill="white" />
    <path
      d="M116 0C102.728 0 92 10.728 92 24C92 37.272 102.728 48 116 48C129.272 48 140 37.272 140 24C140 10.728 129.272 0 116 0ZM126.32 34.32C126.098 34.5425 125.834 34.719 125.544 34.8394C125.254 34.9599 124.942 35.0219 124.628 35.0219C124.314 35.0219 124.002 34.9599 123.712 34.8394C123.422 34.719 123.158 34.5425 122.936 34.32L116 27.384L109.064 34.32C108.615 34.7687 108.007 35.0209 107.372 35.0209C106.737 35.0209 106.129 34.7687 105.68 34.32C105.231 33.8713 104.979 33.2626 104.979 32.628C104.979 32.3138 105.041 32.0026 105.161 31.7123C105.282 31.422 105.458 31.1582 105.68 30.936L112.616 24L105.68 17.064C105.231 16.6153 104.979 16.0066 104.979 15.372C104.979 14.7374 105.231 14.1287 105.68 13.68C106.129 13.2313 106.737 12.9792 107.372 12.9792C108.007 12.9792 108.615 13.2313 109.064 13.68L116 20.616L122.936 13.68C123.158 13.4578 123.422 13.2815 123.712 13.1613C124.003 13.041 124.314 12.9792 124.628 12.9792C124.942 12.9792 125.253 13.041 125.544 13.1613C125.834 13.2815 126.098 13.4578 126.32 13.68C126.542 13.9022 126.718 14.166 126.839 14.4563C126.959 14.7466 127.021 15.0578 127.021 15.372C127.021 15.6862 126.959 15.9974 126.839 16.2877C126.718 16.578 126.542 16.8418 126.32 17.064L119.384 24L126.32 30.936C127.232 31.848 127.232 33.384 126.32 34.32Z"
      fill="#FF3D71"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="146.921"
        x2="150.433"
        y2="21.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>

  <div class="action-icon">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="data.delete"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M24 0C10.728 0 0 10.728 0 24C0 37.272 10.728 48 24 48C37.272 48 48 37.272 48 24C48 10.728 37.272 0 24 0ZM34.32 34.32C34.098 34.5425 33.8342 34.719 33.5439 34.8394C33.2536 34.9599 32.9423 35.0219 32.628 35.0219C32.3137 35.0219 32.0024 34.9599 31.7121 34.8394C31.4218 34.719 31.158 34.5425 30.936 34.32L24 27.384L17.064 34.32C16.6153 34.7687 16.0066 35.0209 15.372 35.0209C14.7374 35.0209 14.1287 34.7687 13.68 34.32C13.2313 33.8713 12.9792 33.2626 12.9792 32.628C12.9792 32.3138 13.041 32.0026 13.1613 31.7123C13.2815 31.422 13.4578 31.1582 13.68 30.936L20.616 24L13.68 17.064C13.2313 16.6153 12.9792 16.0066 12.9792 15.372C12.9792 14.7374 13.2313 14.1287 13.68 13.68C14.1287 13.2313 14.7374 12.9792 15.372 12.9792C16.0066 12.9792 16.6153 13.2313 17.064 13.68L24 20.616L30.936 13.68C31.1582 13.4578 31.422 13.2815 31.7123 13.1613C32.0026 13.041 32.3138 12.9792 32.628 12.9792C32.9422 12.9792 33.2534 13.041 33.5437 13.1613C33.834 13.2815 34.0978 13.4578 34.32 13.68C34.5422 13.9022 34.7185 14.166 34.8387 14.4563C34.959 14.7466 35.0209 15.0578 35.0209 15.372C35.0209 15.6862 34.959 15.9974 34.8387 16.2877C34.7185 16.578 34.5422 16.8418 34.32 17.064L27.384 24L34.32 30.936C35.232 31.848 35.232 33.384 34.32 34.32Z"
        fill="#FF3D71"
      />
    </svg>
  </div>

  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="data.delete"
  >
    <circle cx="70" cy="70" r="70" fill="#88191d" />
    <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_0_1)" />
    <path
      d="M63.7 51.1H76.3C76.3 49.4291 75.6363 47.8267 74.4548 46.6452C73.2733 45.4637 71.6709 44.8 70 44.8C68.3291 44.8 66.7267 45.4637 65.5452 46.6452C64.3638 47.8267 63.7 49.4291 63.7 51.1ZM57.4 51.1C57.4 47.7583 58.7275 44.5534 61.0905 42.1905C63.4534 39.8275 66.6583 38.5 70 38.5C73.3417 38.5 76.5466 39.8275 78.9095 42.1905C81.2725 44.5534 82.6 47.7583 82.6 51.1H98.35C99.1854 51.1 99.9867 51.4319 100.577 52.0226C101.168 52.6134 101.5 53.4146 101.5 54.25C101.5 55.0854 101.168 55.8866 100.577 56.4774C99.9867 57.0681 99.1854 57.4 98.35 57.4H95.5717L92.7808 89.971C92.5125 93.1162 91.0734 96.0461 88.7483 98.181C86.4232 100.316 83.3815 101.5 80.2249 101.5H59.7751C56.6185 101.5 53.5768 100.316 51.2517 98.181C48.9266 96.0461 47.4875 93.1162 47.2192 89.971L44.4283 57.4H41.65C40.8146 57.4 40.0134 57.0681 39.4226 56.4774C38.8319 55.8866 38.5 55.0854 38.5 54.25C38.5 53.4146 38.8319 52.6134 39.4226 52.0226C40.0134 51.4319 40.8146 51.1 41.65 51.1H57.4ZM79.45 70C79.45 69.1646 79.1181 68.3634 78.5274 67.7726C77.9366 67.1819 77.1354 66.85 76.3 66.85C75.4646 66.85 74.6634 67.1819 74.0726 67.7726C73.4819 68.3634 73.15 69.1646 73.15 70V82.6C73.15 83.4354 73.4819 84.2367 74.0726 84.8274C74.6634 85.4181 75.4646 85.75 76.3 85.75C77.1354 85.75 77.9366 85.4181 78.5274 84.8274C79.1181 84.2367 79.45 83.4354 79.45 82.6V70ZM63.7 66.85C62.8646 66.85 62.0634 67.1819 61.4726 67.7726C60.8819 68.3634 60.55 69.1646 60.55 70V82.6C60.55 83.4354 60.8819 84.2367 61.4726 84.8274C62.0634 85.4181 62.8646 85.75 63.7 85.75C64.5354 85.75 65.3366 85.4181 65.9274 84.8274C66.5181 84.2367 66.85 83.4354 66.85 82.6V70C66.85 69.1646 66.5181 68.3634 65.9274 67.7726C65.3366 67.1819 64.5354 66.85 63.7 66.85Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="1.53005"
        y1="137.921"
        x2="150.433"
        y2="12.6813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#b81417" />
        <stop offset="1" stop-color="#88191d" />
      </linearGradient>
    </defs>
  </svg>

  <div class="text-area">
    {{ data.errorText ? data.errorText : "Falha no envio" }}
  </div>
  <div class="buttons-area">
    <button class="full-button" (click)="closeModal()">Confirmar</button>
  </div>
</div>
